import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';
import { Space } from '@lib/uikit/theme/types';
import { CSSProperties } from 'react';

export type VStackProps = {
    spacing?: Space;
    align?: CSSProperties['alignItems'];
    textAlign?: CSSProperties['textAlign'];
    marginTop?: Space;
    marginBottom?: Space;
    marginBlock?: Space;
    paddingBlock?: Space;
    paddingInline?: Space;
    maxHeight?: CSSProperties['maxHeight'];
    justifyContent: CSSProperties['justifyContent'];
    height: CSSProperties['height'];
};

export const VStack = styled.div<StyledProps<VStackProps>>`
    display: flex;
    flex-direction: column;
    align-items: ${({ align }) => align};
    text-align: ${({ textAlign }) => textAlign};
    gap: ${({ theme, spacing }) => theme.spacing[spacing || 'md']};
    margin-top: ${({ theme, marginTop }) =>
        marginTop ? theme.spacing[marginTop] : undefined};
    margin-bottom: ${({ theme, marginBottom }) =>
        marginBottom ? theme.spacing[marginBottom] : undefined};
    gap: ${({ theme, spacing }) => theme.spacing[spacing || 'md']};
    margin-block: ${({ theme, marginBlock }) =>
        marginBlock ? theme.spacing[marginBlock] : undefined};
    padding-block: ${({ theme, paddingBlock }) =>
        paddingBlock ? theme.spacing[paddingBlock] : undefined};
    padding-inline: ${({ theme, paddingInline }) =>
        paddingInline ? theme.spacing[paddingInline] : undefined};
    max-height: ${({ maxHeight }) => maxHeight};
    justify-content: ${({ justifyContent }) => justifyContent};
    height: ${({ height }) => height};
`;
