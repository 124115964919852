import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';
import { Space } from '@lib/uikit/theme/types';
import { CSSProperties } from 'react';

export type HStackProps = {
    alignItems?: CSSProperties['alignItems'];
    justifyContent?: CSSProperties['justifyContent'];
    marginBottom?: Space;
    marginTop?: Space;
    paddingInline?: Space;
    spacing?: Space;
    opacity?: CSSProperties['opacity'];
};

export const HStack = styled.div<StyledProps<HStackProps>>`
    display: flex;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    gap: ${({ theme, spacing }) => theme.spacing[spacing || 'md']};
    justify-content: ${({ justifyContent }) => justifyContent};
    margin-top: ${({ theme, marginTop }) =>
        marginTop ? theme.spacing[marginTop] : undefined};
    margin-bottom: ${({ theme, marginBottom }) =>
        marginBottom ? theme.spacing[marginBottom] : undefined};
    padding-inline: ${({ theme, paddingInline }) =>
        paddingInline ? theme.spacing[paddingInline] : undefined};
    opacity: ${({ opacity }) => opacity};
`;
