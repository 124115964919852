import {
    Modals,
    Enum_Modals_Trigger,
    Scalars,
} from '@graphql/generated/graphql';
import { Queue } from '@utils/queue';

import { TriggerEvent } from './modalsContext';

type ModalState = {
    isOpen: boolean;
    modals: Modals[] | undefined;
    currentModal: Modals | undefined;
    queue: Queue<TriggerEvent>;
};

export enum ActionType {
    EVENT_TRIGGER = 'EVENT_TRIGGER',
    SET_OPEN = 'SET_OPEN',
    SET_MODALS = 'SET_MODALS',
}

type Action =
    | {
          type: ActionType.SET_OPEN;
          isOpen: boolean;
      }
    | {
          type: ActionType.EVENT_TRIGGER;
          event: Enum_Modals_Trigger;
          filters: Record<string, unknown>;
      }
    | {
          type: ActionType.SET_MODALS;
          modals?: Modals[];
      };

const isFilterCompatible = (
    modalFilter: Scalars['JSON']['output'],
    eventFilter: Record<string, unknown>
): boolean => {
    for (const key in modalFilter) {
        if (modalFilter[key] !== eventFilter[key]) {
            return false;
        }
    }

    return true;
};

export const modalsReducer = (
    state: ModalState,
    action: Action
): ModalState => {
    switch (action.type) {
        case ActionType.SET_OPEN:
            return { ...state, isOpen: action.isOpen };

        case ActionType.EVENT_TRIGGER: {
            if (!state.modals)
                throw new Error(
                    '[Modal Reducer] event trigger was called before modal initialization'
                );
            const newModals = state.modals.filter(
                (modal) =>
                    modal?.Trigger === action.event &&
                    isFilterCompatible(modal.Filter, action.filters)
            );

            if (newModals[0]) {
                return { ...state, currentModal: newModals[0], isOpen: true };
            }
            return state;
        }

        case ActionType.SET_MODALS:
            return { ...state, modals: action.modals };

        default:
            throw new Error(
                `Unhandled action type - ${JSON.stringify(action)}`
            );
    }
};
