import { useState, useEffect, useRef, useCallback } from 'react';

interface Size {
    width: number;
    height: number;
    clientWidth: number;
    clientHeight: number;
}

export const useElementSize = (): [
    Size,
    (node: HTMLElement | null) => void,
] => {
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
        clientWidth: 0,
        clientHeight: 0,
    });
    const observerRef = useRef<ResizeObserver | null>(null);

    const ref = useCallback((node: HTMLElement | null) => {
        if (node) {
            if (!observerRef.current) {
                observerRef.current = new ResizeObserver((entries) => {
                    entries.forEach((entry) => {
                        setSize({
                            width: entry.contentRect.width,
                            height: entry.contentRect.height,
                            clientWidth: node.clientWidth,
                            clientHeight: node.clientHeight,
                        });
                    });
                });
            }

            observerRef.current.observe(node);
        }
    }, []);

    useEffect(() => {
        return () => observerRef.current?.disconnect();
    }, []);

    return [size, ref];
};
