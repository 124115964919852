import { NextRouter } from 'next/router';

export type SimpleQuery = {
    [key: string]: string;
};

export const hrefFromAs = (as: string, company: string): string => {
    const HAS_LOCALE = /^\/([a-z]{2}(-[a-z]{2,4})?)(?:\/|$)/i;

    if (HAS_LOCALE.test(as)) {
        // Put companySlug after locale
        const splited = as.split('/');
        splited.splice(1, 0, company);

        return splited.join('/');
    }

    return `/${company}${as}`;
};

export const replaceDynamicParams = (
    path: string,
    params: SimpleQuery
): string => {
    return path.replace(/\[([^\]]+)\]/g, (_, key) => params[key] || '');
};

export const getFullPath = (router: NextRouter): string => {
    return `/${router.locale}${replaceDynamicParams(
        router.pathname,
        router.query as unknown as SimpleQuery
    )}`;
};
