import styled from '@emotion/styled';
import { ReactNode, forwardRef } from 'react';

import { Input, InputProps } from './Input';

export type InputGroupProps = {
    leftElement?: ReactNode;
    rightElement?: ReactNode;
} & InputProps;

const ELEMENT_SIZE = '2.5rem';

const Group = styled.div`
    z-index: 1;
    display: flex;
    position: relative;
`;

const StyledInput = styled(Input)<{ hasLeft: boolean; hasRight: boolean }>`
    cursor: inherit;
    padding-inline-start: ${({ hasLeft }) =>
        hasLeft ? `calc(5px + ${ELEMENT_SIZE})` : ELEMENT_SIZE};
    padding-inline-end: ${({ hasRight }) =>
        hasRight ? `calc(5px + ${ELEMENT_SIZE})` : ELEMENT_SIZE};
`;

const LeftElement = styled.div`
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    width: ${ELEMENT_SIZE};
    padding-inline-start: ${({ theme }) => theme.spacing.sm};
`;

const RightElement = styled.div`
    right: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    width: ${ELEMENT_SIZE};
    padding-inline-end: ${({ theme }) => theme.spacing.sm};
`;

export const InputGroup = forwardRef<HTMLInputElement, InputGroupProps>(
    ({ variant, leftElement, rightElement, ...rest }, ref) => {
        return (
            <Group>
                {leftElement && <LeftElement>{leftElement}</LeftElement>}
                <StyledInput
                    variant={variant}
                    {...rest}
                    ref={ref}
                    hasLeft={!!leftElement}
                    hasRight={!!rightElement}
                />
                {rightElement && <RightElement>{rightElement}</RightElement>}
            </Group>
        );
    }
);

InputGroup.displayName = 'InputGroup';
