import { InitOptions, Integrations } from '@segment/analytics-next';
import { OptionsType } from 'cookies-next/lib/types';

export const PREFERENCES_KEY = 'tracking-preferences';
export const PREFERENCES_VERSION = 3;

export const PERFORMANCE_SAMPLING_RATE = 0.2;
export const PERFORMANCE_MAX_RETRY = 10;
export const PERFORMANCE_RETRY_DELAY_IN_MS = 500;

export const DEFAULT_COOKIE_OPTIONS: Partial<OptionsType> = {
    sameSite: 'none',
    secure: true,
};

export const AJS_COOKIE_OPTIONS: OptionsType = {
    ...DEFAULT_COOKIE_OPTIONS,
    maxAge: 365,
    path: '/',
};

export const DEFAULT_INTEGRATIONS: Integrations = {
    All: true,
};

export const GIVEN_CONSENT: InitOptions = {
    disableClientPersistence: false,
    integrations: DEFAULT_INTEGRATIONS,
};

export const REJECTED_CONSENT: InitOptions = {
    disableClientPersistence: true,
    integrations: DEFAULT_INTEGRATIONS,
};

export const IS_ANALYTICS_ENABLED =
    String(process.env.NEXT_PUBLIC_DISABLE_ANALYTICS) !== 'true';
