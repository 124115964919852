/**
 * Gets the user's preferred language.
 *
 * @returns The user's preferred language.
 *
 * @throws If the method is not called on the client side.
 */
export const getUserPreferredLanguage = (): string => {
    if (typeof window === 'undefined') {
        throw new Error(
            '[getUserPreferredLanguage] should be called on the client side'
        );
    }

    const [language, region] = navigator.language.split('-');

    if (typeof region === 'string' && region !== '') {
        return region;
    }

    return language;
};
