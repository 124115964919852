import { NewsletterForm } from '@components/form/templates/NewsletterForm';
import { ReviewForm } from '@components/form/templates/ReviewForm';
import { CustomerInputType } from '@hello-pimster/pimster-crm-sdk';
import { UseFormSubmitMutate } from '@hooks';
import { SinglePageTemplateForm as SinglePageTemplateFormType } from '@types';

import { RegistrationForm } from '../templates/RegistrationForm';

interface Props {
    form: SinglePageTemplateFormType;
    customerInputType?: CustomerInputType;
    isSubmitting: boolean;
    onSubmit: UseFormSubmitMutate;
}

export const SinglePageTemplateForm = ({
    form,
    customerInputType,
    isSubmitting,
    onSubmit,
}: Props) => {
    const formTemplate = form.Elements[0];

    switch (formTemplate.__typename) {
        case 'ComponentFormTemplatesNewsletter':
            return (
                <NewsletterForm
                    formName={form.Name}
                    formUID={form.UID || ''}
                    companyName={form.Company?.Name || ''}
                    title={formTemplate.Title}
                    description={formTemplate.Description}
                    isNameRequired={formTemplate.IsNameRequired}
                    emailConsent={formTemplate.EmailConsent}
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                />
            );

        case 'ComponentFormTemplatesRegistration':
            return (
                <RegistrationForm
                    formName={form.Name}
                    formUID={form.UID || ''}
                    companyName={form.Company?.Name || ''}
                    customerInputType={customerInputType!}
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                />
            );

        case 'ComponentFormTemplatesReview':
            return (
                <ReviewForm
                    formName={form.Name}
                    formUID={form.UID || ''}
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                />
            );

        default:
            return null;
    }
};
