import { useState } from 'react';

interface UseDisclosureProps {
    defaultIsOpen?: boolean;
}

export const useDisclosure = (props?: UseDisclosureProps) => {
    const [isOpen, setIsOpen] = useState(Boolean(props?.defaultIsOpen));

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const onToggle = () => {
        setIsOpen((previous) => !previous);
    };

    return { isOpen, onOpen, onClose, onToggle };
};
