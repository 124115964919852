import { FORM } from '@constants';
import { Input } from '@lib/uikit';
import { useMaskito } from '@maskito/react';
import { fromLocalString, toLocalString } from '@utils/date';
import { FieldInputProps } from 'formik';
import { useTranslation } from 'next-i18next';
import { useMemo, useState } from 'react';

type FallbackProps = {
    fieldProps: FieldInputProps<Date>;
    value: Date | null;
    locale: string;
    onValidDate: (date: Date) => void;
    onError: (error: string | undefined) => void;
    errors: string | undefined;
};

export const DateFallbackInput = ({
    fieldProps,
    value,
    locale,
    onValidDate,
    onError,
    errors,
}: FallbackProps) => {
    const [isValidDate, setIsValidDate] = useState<boolean>(true);

    const { t } = useTranslation(FORM);

    const mask = useMemo(() => {
        const formatter = new Intl.DateTimeFormat(locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        const parts = formatter.formatToParts(new Date());
        return parts
            .map(({ value, type }) => {
                if (type == 'literal') return value;
                return value.split('').map(() => new RegExp('[0-9]'));
            })
            .flat();
    }, [locale]);

    const placeholder = useMemo(() => {
        const formatter = new Intl.DateTimeFormat(locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        const parts = formatter.formatToParts(new Date());
        return parts
            .map(({ value, type }) => {
                if (type == 'literal') return value;
                return value.replace(/\d/g, type[0].toUpperCase());
            })
            .join('');
    }, [locale]);

    const inputRef = useMaskito({ options: { mask } });

    const [stringValue, setStringValue] = useState(
        value ? toLocalString(value, locale) : undefined
    );

    const onChange = (value: string) => {
        onError(undefined);
        setStringValue(value);
        try {
            if (!value) return;
            const date = fromLocalString(value, locale);
            if (date) {
                setIsValidDate(true);
                onValidDate(date);
            }
        } catch (e) {
            setIsValidDate(false);
        }
    };

    const handleValidation = () => {
        if (!isValidDate) {
            onError(t('errors.date.format'));
        }
    };

    return (
        <Input
            {...fieldProps}
            type='text'
            placeholder={placeholder}
            ref={inputRef}
            value={stringValue}
            onInput={(e) => {
                e.currentTarget.value;
                onChange(e.currentTarget.value);
            }}
            isInvalid={!!errors}
            onBlur={handleValidation}
        />
    );
};
