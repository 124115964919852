const rtlLocales: string[] = [
    'ar',
    'arc',
    'dv',
    'fa',
    'ha',
    'he',
    'khw',
    'ks',
    'ku',
    'ps',
    'ur',
    'yi',
];

const getLocaleDirection = (locale?: string) => {
    if (!locale) return 'ltr';
    return rtlLocales.includes(locale) ? 'rtl' : 'ltr';
};

export default getLocaleDirection;
