import { EmailConsentFragment } from '@graphql/generated/graphql';
import {
    FormFieldComponent,
    FormFieldOrConsent,
    FormPageComponent,
    FormTemplateComponent,
} from '@types';

interface GqlEntity {
    __typename?: string;
}

export const isFormFieldOrConsent = (
    component: GqlEntity
): component is FormFieldOrConsent =>
    isFormField(component) || isConsent(component);

export const isFormField = (
    component: GqlEntity
): component is FormFieldComponent => {
    return Boolean(component.__typename?.includes('FormFields'));
};

export const isFormPage = (
    component: GqlEntity
): component is FormPageComponent => {
    return Boolean(component.__typename?.includes('FormPages'));
};

export const isFormTemplate = (
    component: GqlEntity
): component is FormTemplateComponent => {
    return Boolean(component.__typename?.includes('FormTemplates'));
};

export const isFormElement = (
    component: GqlEntity
): component is
    | FormFieldComponent
    | FormPageComponent
    | FormTemplateComponent => {
    return (
        isFormField(component) ||
        isFormPage(component) ||
        isFormTemplate(component)
    );
};

export const isConsent = (
    component: GqlEntity
): component is EmailConsentFragment => {
    return component.__typename === 'ComponentConsentsEmail';
};
