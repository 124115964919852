import { Theme, css } from '@emotion/react';

const globals = (theme: Theme) => css`
    * {
        box-sizing: border-box;
        border: 0;
        padding: 0;
        margin: 0;
    }

    html {
        block-size: 100%;
        font-size: clamp(15px, calc(60% + 1vmin), 20px);
        height: 100%;
        overscroll-behavior: contain;
        scroll-behavior: smooth;
        scroll-padding-top: 40vh;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    body {
        /* It corresponds to the dark color of the theme */
        height: 100%;
        overflow-anchor: none;
        overflow: hidden;
    }

    #__next {
        /* min-height: 100%; */
        height: 100%;
        /* display: flex; */
    }

    #headlessui-portal-root {
        width: 100%;
    }

    @media only screen and (min-width: 748px) {
        body {
            margin: 0 auto;
            border-radius: ${theme.shape.radius.default};
            margin-block: 1rem;
            max-width: 748px;
            height: 100%;
            max-height: 1330px;
            box-shadow: 0px 0px 38px rgba(46, 46, 46, 0.3);
        }
        @supports (aspect-ratio: 9/16) {
            body {
                aspect-ratio: 9/16;
            }
        }
        @supports not (aspect-ratio: 9/16) {
            body {
                width: 100%;
            }
        }
    }

    body *::-webkit-scrollbar {
        display: none; /*Chrome and Safari and Edge and Opera*/
    }

    body * {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        -webkit-tap-highlight-color: transparent; /* Chrome */
    }

    * :focus-visible {
        outline: none !important;
    }

    /* Maintain scrollbar for tips and tables on desktop */
    @media (min-aspect-ratio: 3/4) {
        /* Chrome and Safari and Edge and Opera */
        .carousel_element_wrapper::-webkit-scrollbar,
        .table_container::-webkit-scrollbar {
            display: block;
            width: 6px;
            height: 4px;
        }

        /* Incompatibility on Safari */
        .carousel_element_wrapper::-webkit-scrollbar-track,
        .table_container::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Incompatibility on Safari */
        .carousel_element_wrapper::-webkit-scrollbar-thumb,
        .table_container::-webkit-scrollbar-thumb {
            background: #949493;
            border-radius: 1.5rem;
        }

        /* Incompatibility on Safari */
        .carousel_element_wrapper::-webkit-scrollbar-thumb:hover,
        .table_container::-webkit-scrollbar-thumb:hover {
            background: #949493;
        }

        /* Firefox */
        .carousel_element_wrapper,
        .table_container {
            scrollbar-width: thin;
            scrollbar-color: #949493;
            -ms-overflow-style: auto;
        }
    }

    /* Set font size of interactive element to 16px so that is does not zoom when focus */
    @media screen and (max-width: 767px) {
        input,
        select,
        textarea {
            font-size: 16px;
        }
    }

    button,
    input,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
    }

    input[type='text']::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
    input[type='text']::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        display: none;
    }

    input[type='text']::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }
    input[type='text']::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        display: none;
    }

    a {
        color: inherit;
        text-decoration: inherit; /* no underline */
    }

    a:visited > div {
        color: inherit; /* applied to avoid Safari visited link default border*/
    }

    html[dir='rtl'] .story_container .story-progress,
    html[dir='rtl']
        span.material-icons-round[data-icon-name='format_align_left'],
    html[dir='rtl'] span.material-icons-round[data-icon-name='search'],
    html[dir='rtl'] span.material-icons-round[data-icon-name='arrow_forward'],
    html[dir='rtl'] span.material-icons-round[data-icon-name='arrow_back'],
    html[dir='rtl']
        span.material-icons-round[data-icon-name='keyboard_arrow_left'],
    html[dir='rtl']
        span.material-icons-round[data-icon-name='keyboard_arrow_right'] {
        transform: scaleX(-1);
    }
`;

export default globals;
