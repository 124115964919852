import { useAnalytics } from '@lib/analytics/analyticsContext';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

export const useSentry = () => {
    const { analytics } = useAnalytics();

    useEffect(() => {
        const setSentryUser = async () => {
            const user = await analytics?.user();
            const userId = user.id() ?? undefined;
            const group = await analytics.group();
            if (user) {
                Sentry.setUser({
                    id: userId,
                    anonymousId: user.anonymousId(),
                    isAnonymous: typeof userId === 'undefined',
                    groupId: group?.id() ?? undefined,
                });
            } else {
                console.error(
                    '[Sentry] No user found for Sentry initialization'
                );
            }
        };
        setSentryUser();
    }, [analytics]);
};
