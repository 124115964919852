import styled from '@emotion/styled';

export const FullPageWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    @media (min-width: 748px) {
        border-radius: ${({ theme }) => theme.shape.radius.default};
    }
`;

FullPageWrapper.defaultProps;
