import { useAnalytics } from '@lib/analytics/analyticsContext';
import { ID } from '@segment/analytics-next';
import { distinctArray } from '@utils/array/distinct';
import axios, { AxiosRequestTransformer, AxiosResponse } from 'axios';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { ReactNode, createContext, useContext, useEffect } from 'react';

import {
    POSTHOG_DECIDE_ENDPOINT,
    POSTHOG_LOCAL_STORAGE_FEATURES_KEY,
} from '../constant';
import { FeatureFlagKey } from '../enums';
import { Feature } from '../types';

interface PostHogContext {
    callFeature: (featureFlagKey: FeatureFlagKey) => Promise<Feature>;
}

export const PostHogContext = createContext<PostHogContext>(
    {} as PostHogContext
);

interface Props {
    children: ReactNode;
}

export const PostHogProvider = ({ children }: Props) => {
    const {
        locale,
        query: { company, product },
    } = useRouter();
    const { analytics, eventProperties, setEventProperties } = useAnalytics();
    const api = axios.create({
        baseURL: process.env.NEXT_PUBLIC_POSTHOG_BASE_URL,
        transformRequest: [
            (data) => {
                if (data) {
                    data.api_key =
                        process.env.NEXT_PUBLIC_POSTHOG_PROJECT_API_KEY;
                }

                return data;
            },
            ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
        ],
    });

    const _loadFeatures = debounce(
        async (): Promise<Feature[]> => {
            try {
                const localFeatures = _getLocalFeatures();
                const userId = await _getUserId();

                if (!userId) {
                    return distinctArray(
                        (feature) => feature.key,
                        localFeatures
                    );
                }

                const resp = await _callDecide(userId);
                const fetchedFeatures = _parseFetchedFeatures(resp);
                const validFeatures = _getValidFeatures(fetchedFeatures);
                const aggregatedFeatures = distinctArray(
                    (feature) => feature.key,
                    [...validFeatures, ...localFeatures]
                );

                _setLocalFeatures(aggregatedFeatures);

                return aggregatedFeatures;
            } catch (err) {
                return [];
            }
        },
        500,
        { leading: true }
    );

    useEffect(() => {
        _loadFeatures();
    }, [_loadFeatures]);

    /* -------------------------------------------------------------------------- */
    /*                              FEATURES METHODS                              */
    /* -------------------------------------------------------------------------- */

    const _localStorageKey = (key: string): string => `${company}_${key}`;

    const _getLocalFeatures = (): Feature[] => {
        return JSON.parse(
            localStorage.getItem(
                _localStorageKey(POSTHOG_LOCAL_STORAGE_FEATURES_KEY)
            ) ?? '[]'
        );
    };

    const _setLocalFeatures = (features: Feature[]): void => {
        localStorage.setItem(
            _localStorageKey(POSTHOG_LOCAL_STORAGE_FEATURES_KEY),
            JSON.stringify(features)
        );
    };

    const _getLocalFeatureByFeatureFlagKey = (
        key: FeatureFlagKey
    ): Feature | undefined => {
        return _getLocalFeatures().find(
            (localFeature) => localFeature.key === key
        );
    };

    const _getUserId = async (): Promise<ID> => {
        const user = await analytics.user();
        return user.id() || user.anonymousId();
    };

    const _parseFetchedFeatures = (resp: AxiosResponse): Feature[] => {
        return Object.keys(resp.data['featureFlags']).map((key) => ({
            key: key as FeatureFlagKey,
            variant: resp.data['featureFlags'][key],
            payload: resp.data['featureFlagPayloads'][key],
        }));
    };

    const _getValidFeatures = (features: Feature[]): Feature[] => {
        return features.filter((feature) => feature.variant !== false);
    };

    const _parseFeature = (feature: Feature): Feature => {
        const { payload } = feature;

        if (typeof payload === 'string') {
            feature.payload = JSON.parse(payload);
        }

        return feature;
    };

    const _callDecide = (userId: ID): Promise<AxiosResponse> => {
        return api.post(POSTHOG_DECIDE_ENDPOINT, {
            distinct_id: userId,
            person_properties: {
                locale,
                company,
                product,
                environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
                $current_url: window.location.toString(),
            },
        });
    };

    const callFeature = async (
        featureFlagKey: FeatureFlagKey
    ): Promise<Feature> => {
        let feature = _getLocalFeatureByFeatureFlagKey(featureFlagKey);

        if (!feature) {
            await _loadFeatures();
            feature = _getLocalFeatureByFeatureFlagKey(featureFlagKey);
        }

        if (!feature)
            throw new Error(
                `[POSTHOG]: featureFlagKey ${featureFlagKey} not found`
            );

        if (!feature.hasBeenCalled) {
            analytics.track('$feature_flag_called', {
                $feature_flag_response: feature.variant,
                $feature_flag: featureFlagKey,
            });
            feature.hasBeenCalled = true;
            analytics.track('Feature Flag Called', {
                $set: {
                    [featureFlagKey]: feature.variant,
                },
            });
        }

        setEventProperties({
            ...eventProperties,
            ['$feature/' + feature.key]: feature.variant,
        });

        return _parseFeature(feature);
    };

    return (
        <PostHogContext.Provider value={{ callFeature }}>
            {children}
        </PostHogContext.Provider>
    );
};

export const usePostHog = () => useContext(PostHogContext);
