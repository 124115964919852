import Icon, { IconProps } from '@components/utils/icons/icon';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps, ElementType, FC } from 'react';

type CustomProps = {
    ariaLabel: NonNullable<HTMLButtonElement['ariaLabel']>;
    icon: IconProps['name'];
    as?: ElementType;
    color?: IconProps['color'];
    size?: IconProps['size'];
};

export type IconButtonProps = CustomProps & ComponentProps<'button'>;

const Button = styled.button`
    pointer-events: auto;
    cursor: pointer;
    width: auto;
    height: auto;
    padding: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconButton: FC<IconButtonProps> = ({
    ariaLabel,
    icon,
    color,
    as = 'button',
    size = 'medium',
    ...rest
}) => {
    const theme = useTheme();

    return (
        <Button aria-label={ariaLabel} as={as} {...rest}>
            <Icon
                name={icon}
                size={size}
                color={color || theme.palette.common.white}
            />
        </Button>
    );
};
