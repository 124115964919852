import { AnalyticsBrowser } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { EmbedCookieConsent } from '../analyticsContext';
import { DEFAULT_INTEGRATIONS } from '../constants';
import { hasSavedPreferences, savePreferences } from '../preferences';
import { setUserCookies } from '../utils';

export const useConsent = (
    analytics: AnalyticsBrowser,
    resetAnalytics: () => void
) => {
    const {
        isReady,
        query: { cookie_consent },
    } = useRouter();

    const [showConsentPrompt, setShowConsentPrompt] = useState<boolean>(false);

    const setConsent = useCallback(
        async (consent: boolean) => {
            if (consent) await setUserCookies(analytics);
            setShowConsentPrompt(false);
            savePreferences(consent, DEFAULT_INTEGRATIONS);
            resetAnalytics();
        },
        [analytics, resetAnalytics]
    );

    useEffect(() => {
        if (!isReady) return;
        // if preference exists do not show consent, analytics has already loaded with appropriate pref
        if (hasSavedPreferences()) return;

        switch (cookie_consent) {
            case EmbedCookieConsent.Ask:
                setShowConsentPrompt(true);
                break;
            case EmbedCookieConsent.Accepted:
                setConsent(true);
                break;
            case EmbedCookieConsent.Rejected:
                setConsent(false);
                break;
            default:
                if (cookie_consent) {
                    console.error(
                        '[Analytics] Invalid cookie_consent parameter'
                    );
                }

                setShowConsentPrompt(true);
        }
    }, [isReady, cookie_consent, setConsent]);

    return {
        showConsentPrompt,
        setConsent,
    };
};
