export const typography = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontFamily: 'Inter, sans-serif',
    heading1: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '1.875rem',
        lineHeight: '2.25rem',
        fontFamily: 'Inter, sans-serif',
    },
    heading2: {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontFamily: 'Inter, sans-serif',
    },
    heading3: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        fontFamily: 'Inter, sans-serif',
    },
    heading4: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        fontFamily: 'Inter, sans-serif',
    },
    heading5: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: 'Inter, sans-serif',
    },
    subtitle1: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.875rem',
        lineHeight: '2.25rem',
        fontFamily: 'Inter, sans-serif',
    },
    subtitle2: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontFamily: 'Inter, sans-serif',
    },
    subtitle3: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        fontFamily: 'Inter, sans-serif',
    },
    subtitle4: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        fontFamily: 'Inter, sans-serif',
    },
    subtitle5: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: 'Inter, sans-serif',
    },
    body: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: 'Inter, sans-serif',
    },
    bodyMedium: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: 'Inter, sans-serif',
    },
    caption: {
        fontStyle: 'normal',
        fontWeight: 200,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontFamily: 'Inter, sans-serif',
    },
    captionMedium: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontFamily: 'Inter, sans-serif',
    },
    button: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: 'Inter, sans-serif',
        textTransform: 'none',
    },
};
