import { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import AnimationLayout from '@components/layouts/animationLayout';
import { ContextualModal } from '@components/modals/custom/ContextualModal';
import { PreviewBar } from '@components/preview/PreviewBar';
import { EditProvider } from '@components/utils/contexts/EditContext';
import { EmbedProvider } from '@components/utils/contexts/EmbedContext';
import {
    ModalProvider,
    TriggerEvent,
} from '@components/utils/contexts/modals/modalsContext';
import { ProductNavigationProvider } from '@components/utils/contexts/ProductNavigationContext';
import { UserProvider } from '@components/utils/contexts/UserContext';
import { WhiteLabelProvider } from '@components/utils/contexts/WhiteLabelContext';
import InitRouter from '@components/utils/router/initRouter';
import { TrackEvent } from '@enums';
import { useEventQueue } from '@hooks/useEventQueue';
import { useApollo } from '@lib//apollo/apollo';
import { AnalyticsProvider } from '@lib/analytics/analyticsContext';
import {
    PERFORMANCE_SAMPLING_RATE,
    PERFORMANCE_MAX_RETRY,
    PERFORMANCE_RETRY_DELAY_IN_MS,
} from '@lib/analytics/constants';
import ApiCrmProvider from '@lib/pimster-crm/provider/ApiCrmProvider';
import { PostHogProvider } from '@lib/posthog/providers/PostHogProvider';
import { InitSentry } from '@lib/sentry/InitSentry';
import { ThemeProvider } from '@lib/uikit';
import { CompanyConfig } from '@lib/uikit/utils/getCompanyConfig';
import * as Sentry from '@sentry/nextjs';
import localeDirection from '@utils/locale/direction';
import { MotionConfig } from 'framer-motion';
import parseHTML from 'html-react-parser';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { useCallback, useEffect } from 'react';

import nextI18NextConfig from '../next-i18next.config';

type RequiredPageProps = {
    initialApolloState?: NormalizedCacheObject | null;
    isPreview?: boolean;
} & CompanyConfig;

const MyApp = ({ Component, pageProps }: AppProps<RequiredPageProps>) => {
    const apolloClient = useApollo(
        pageProps.isPreview ?? false,
        pageProps.initialApolloState ?? null
    );
    const eventQueue = useEventQueue();
    const {
        locale,
        query: { company, product },
    } = useRouter();

    useEffect(() => {
        const htmlNode = document.querySelector('html');

        if (htmlNode) {
            htmlNode.setAttribute('dir', localeDirection(locale));
        }

        Sentry.setTag('locale', locale);
    }, [locale]);

    useEffect(() => {
        Sentry.setTag(
            'company',
            Array.isArray(company) ? company.at(0) : company
        );
        Sentry.setTag(
            'product',
            Array.isArray(product) ? product.at(0) : product
        );
    }, [company, product]);

    const handleEvent = useCallback(
        (event: TriggerEvent) => {
            eventQueue.enqueue(event);
        },
        [eventQueue]
    );

    return (
        <>
            <Head>
                <meta
                    name='last-modified'
                    content={new Date().toUTCString().slice(0, -4)}
                />
                {pageProps.companyCustomHead &&
                    parseHTML(pageProps.companyCustomHead)}
            </Head>
            <ThemeProvider companyTheme={pageProps.companyTheme}>
                <WhiteLabelProvider whiteLabel={pageProps.whiteLabel}>
                    <ApolloProvider client={apolloClient}>
                        <EditProvider>
                            <AnalyticsProvider onEvent={handleEvent}>
                                <InitSentry />
                                <EmbedProvider>
                                    <PostHogProvider>
                                        <ApiCrmProvider>
                                            <ProductNavigationProvider>
                                                <ModalProvider
                                                    eventQueue={eventQueue}
                                                >
                                                    <ContextualModal />
                                                </ModalProvider>
                                                <InitRouter />
                                                <PreviewBar
                                                    isPreview={
                                                        pageProps.isPreview
                                                    }
                                                />
                                                <UserProvider>
                                                    <MotionConfig reducedMotion='user'>
                                                        <AnimationLayout>
                                                            <Component
                                                                {...pageProps}
                                                            />
                                                        </AnimationLayout>
                                                    </MotionConfig>
                                                </UserProvider>
                                            </ProductNavigationProvider>
                                        </ApiCrmProvider>
                                    </PostHogProvider>
                                </EmbedProvider>
                            </AnalyticsProvider>
                        </EditProvider>
                    </ApolloProvider>
                </WhiteLabelProvider>
            </ThemeProvider>
            <Script
                id='app-script'
                strategy='afterInteractive'
                dangerouslySetInnerHTML={{
                    __html: `(function(h,o,t,j,a,r){
                                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                    h._hjSettings={hjid:2622559,hjsv:6};
                                    a=o.getElementsByTagName('head')[0];
                                    r=o.createElement('script');r.async=1;
                                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                    a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
                }}
            />
        </>
    );
};

export function reportWebVitals({
    id,
    name,
    label,
    value,
}: NextWebVitalsMetric) {
    const randomNumber = Math.random();
    if (randomNumber < PERFORMANCE_SAMPLING_RATE) {
        const trackPerformanceEvent = () => {
            // @ts-ignore
            window.analytics.track(TrackEvent.PerformanceMetric, {
                name,
                event_category:
                    label === 'web-vital'
                        ? 'Web Vitals'
                        : 'Next.js custom metric',
                value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
                event_label: id, // id unique to current page load
                non_interaction: true,
            });
        };

        let retryCount = 0;
        const interval = setInterval(() => {
            // @ts-ignore
            if (window.analytics) {
                clearInterval(interval);
                trackPerformanceEvent();
            } else {
                retryCount++;
                if (retryCount >= PERFORMANCE_MAX_RETRY) {
                    console.warn(
                        `Performance metric couldn't be sent after  ${PERFORMANCE_MAX_RETRY} retries`
                    );
                    clearInterval(interval);
                }
            }
        }, PERFORMANCE_RETRY_DELAY_IN_MS);
    }
}

//  @ts-ignore : Error exist because next-i18n.config is not typed
export default appWithTranslation(MyApp, nextI18NextConfig);
