export const opacity = {
    '00': '0',
    '00_hex': '00',
    10: '25',
    '10_hex': '19',
    20: '51',
    '20_hex': '33',
    25: '64',
    '25_hex': '3F',
    30: '76',
    '30_hex': '4C',
    40: '102',
    '40_hex': '66',
    50: '127',
    '50_hex': '7F',
    60: '153',
    '60_hex': '99',
    70: '178',
    '70_hex': 'B2',
    75: '191',
    '75_hex': 'BF',
    80: '204',
    '80_hex': 'CC',
    90: '229',
    '90_hex': 'E5',
    100: '255',
    '100_hex': 'FF',
};
