import { EventTriggerSource, TrackEvent } from '@enums';
import { FormPageFragment } from '@graphql/generated/graphql';
import { useFormSubmit, useStep } from '@hooks';
import { UseFormSubmitMutateArgs } from '@hooks/useFormSubmit';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import {
    DynamicFormSchemaType,
    FormPageElementsDynamicZone,
    MultiPagesForm,
} from '@types';
import { useRouter } from 'next/router';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { SurveyMethod } from '../types';

type SurveyContextProviderProps = {
    survey?: MultiPagesForm;
    triggerSource: EventTriggerSource;
    methods: SurveyMethod;
    isRedirecting?: boolean;
};

type SurveyContextType = SurveyContextProviderProps & {
    numberOfPages: number;
    surveyPages: FormPageFragment[];
    currentStep: number;
    currentForm: FormPageElementsDynamicZone;
    currentSurveyPage: FormPageFragment | null;
    canGoToNextStep: boolean;
    isInfoPage: boolean;
    isLastPage: boolean;
    surveyForm: FormPageElementsDynamicZone;
    goToNextSurveyPage: (data?: DynamicFormSchemaType) => void | Promise<void>;
    goToPreviousSurveyPage: () => void;
};

const partialInitialContext: SurveyContextType = {
    surveyPages: [],
    survey: undefined,
    numberOfPages: 0,
    currentStep: 0,
    currentSurveyPage: null,
    currentForm: [],
    canGoToNextStep: false,
    isInfoPage: false,
    isLastPage: false,
    triggerSource: EventTriggerSource.MODULE,
    surveyForm: [],
    isRedirecting: false,
    goToNextSurveyPage: () => {},
    goToPreviousSurveyPage: () => {},
    methods: {
        onSurveyQuit: () => {},
        onSurveySuccess: () => {},
    },
};

export const SurveyContext = createContext<SurveyContextType>(
    partialInitialContext
);

export const SurveyContextProvider: React.FC<
    SurveyContextProviderProps & { children: React.ReactNode }
> = ({ children, survey, methods, triggerSource, isRedirecting }) => {
    const { onSurveyQuit, onSurveySuccess } = methods;
    const { events: routerEvents, asPath } = useRouter();
    const { track } = useAnalytics();

    const { onSubmit } = useFormSubmit({
        formName: survey?.Name || '',
        formUID: survey?.UID || '',
        isProductRegistration: survey?.IsProductRegistration ?? false,
    });

    const [isSurveySubmitted, setIsSurveySubmitted] = useState<boolean>(false);
    const [isDroppingOff, setIsDroppingOff] = useState<boolean>(false);

    const numberOfPages = useMemo(() => {
        return survey?.Elements.length || 0;
    }, [survey]);

    const [
        currentStep,
        { goToNextStep, goToPrevStep, canGoToPrevStep, canGoToNextStep },
    ] = useStep(numberOfPages);

    const surveyPages = useMemo(() => {
        return [...(survey?.Elements.map((el) => el.FormPage!) || [])];
    }, [survey]);

    const currentSurveyPage = useMemo(() => {
        return surveyPages[currentStep - 1] || null;
    }, [currentStep, surveyPages]);

    const currentForm = useMemo(() => {
        return currentSurveyPage.Elements || [];
    }, [currentSurveyPage]);

    const isInfoPage = useMemo(() => {
        return (
            currentSurveyPage.Elements.length === 1 &&
            currentSurveyPage.Elements[0].__typename ===
                'ComponentDisplayRichText'
        );
    }, [currentSurveyPage]);

    const isLastPage = useMemo(() => {
        return currentStep === numberOfPages;
    }, [currentStep, numberOfPages]);

    const surveyForm = useMemo(() => {
        return (surveyPages?.map((page) => page.Elements) || []).flat();
    }, [surveyPages]);

    const trackSurveyPageView = useCallback(() => {
        if (!isSurveySubmitted && !isDroppingOff) {
            track(TrackEvent.SurveyPageView, {
                survey_slug: survey?.Slug || '',
                step_number: currentStep,
                trigger_source: triggerSource,
                is_info_page: isInfoPage,
                page_slug: currentSurveyPage.Slug || '',
                total_nb_steps: numberOfPages,
            });
        }
    }, [
        currentStep,
        isInfoPage,
        triggerSource,
        currentSurveyPage,
        survey,
        numberOfPages,
        isSurveySubmitted,
        isDroppingOff,
        track,
    ]);

    const trackSurveySubmitted = useCallback(() => {
        setIsSurveySubmitted(true);
        track(TrackEvent.SurveySubmitted, {
            survey_slug: survey?.Slug || '',
            total_nb_steps: numberOfPages,
            trigger_source: triggerSource,
        });
    }, [survey, numberOfPages, triggerSource, track]);

    const trackSurveyDropOff = useCallback(
        (targetUrl: string) => {
            if (
                !isSurveySubmitted &&
                asPath.includes('survey') &&
                !targetUrl.includes('survey')
            ) {
                setIsDroppingOff(true);
                track(TrackEvent.SurveyDropOff, {
                    survey_slug: survey?.Slug || '',
                    step_number: currentStep,
                    trigger_source: triggerSource,
                    total_nb_steps: numberOfPages,
                    page_slug: currentSurveyPage.Slug || '',
                });
            }
        },
        [
            isSurveySubmitted,
            currentStep,
            triggerSource,
            numberOfPages,
            survey,
            currentSurveyPage,
            asPath,
            track,
        ]
    );

    const goToNextSurveyPage = useCallback(
        async (data?: DynamicFormSchemaType) => {
            if (canGoToNextStep) {
                return goToNextStep();
            }

            trackSurveySubmitted();
            onSubmit(
                {
                    data: { ...data },
                    customerInputType: 'SURVEY',
                } as UseFormSubmitMutateArgs,
                { onSuccess: () => onSurveySuccess(data) }
            );
        },
        [
            canGoToNextStep,
            goToNextStep,
            trackSurveySubmitted,
            onSubmit,
            onSurveySuccess,
        ]
    );

    const goToPreviousSurveyPage = useCallback(() => {
        if (canGoToPrevStep) {
            goToPrevStep();
        } else {
            onSurveyQuit();
        }
    }, [canGoToPrevStep, goToPrevStep, onSurveyQuit]);

    useEffect(() => {
        trackSurveyPageView();
        routerEvents.on('routeChangeStart', trackSurveyDropOff);
        return () => {
            routerEvents.off('routeChangeStart', trackSurveyDropOff);
        };
    }, [currentStep, routerEvents, trackSurveyPageView, trackSurveyDropOff]);

    const initialContext: SurveyContextType = {
        ...partialInitialContext,
        surveyPages,
        currentStep,
        currentSurveyPage,
        currentForm,
        isInfoPage,
        isLastPage,
        isRedirecting,
        numberOfPages,
        survey,
        canGoToNextStep,
        methods,
        triggerSource,
        surveyForm,
        goToNextSurveyPage,
        goToPreviousSurveyPage,
    };

    return (
        <SurveyContext.Provider value={initialContext}>
            {children}
        </SurveyContext.Provider>
    );
};

export const useSurveyContext = () =>
    useContext<SurveyContextType>(SurveyContext);
