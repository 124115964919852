import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useGetImagePropsByUrlQuery } from '@graphql/generated/graphql';
import { PropsType as StrapiImagePropsType } from '@lib/image/strapiImage';
import { getStrapiAssetUrl } from '@lib/strapi';
import React from 'react';

type PropsType = {
    src: string;
    ImageComponent: React.FC<StrapiImagePropsType>;
};

const shimmer = keyframes`
  100% {
    transform: translateY(-100%);
  }
`;

const Loader = styled.div`
    background-color: ${(props) => props.theme.palette.primary.main};
    position: relative;
    --border-radius: ${(props) => props.theme.spacing.md};
    clip-path: inset(0 0 0 0 round var(--border-radius));
    aspect-ratio: 1;
    max-width: 100%;
    max-height: 20rem;
    margin-inline: auto;
    margin-block: ${(props) => props.theme.spacing.sm};

    & ::before {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100%);
        background-image: ${(props) => props.theme.gradients.loader};
        animation: ${shimmer} 2s infinite;
        content: '';
    }
`;

const ImageMarkdown: React.FC<PropsType> = ({ src, ImageComponent }) => {
    const { loading, error, data } = useGetImagePropsByUrlQuery({
        variables: { url: src },
    });

    if (loading) return <Loader />;
    if (error) return <p>Error! {error.message}</p>;
    if (!data?.files?.[0]) return <p>{"Error! Can't get this image."}</p>;

    const { alternativeText, formats, hash, ext } = data!.files![0]!;
    const asset = {
        hash: hash,
        ext: ext,
    };

    return (
        <ImageComponent
            src={getStrapiAssetUrl(asset)}
            formats={formats}
            alt={alternativeText}
            sizes='35vw'
        />
    );
};

export default ImageMarkdown;
