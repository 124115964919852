export const fromISO = (string: string): Date => {
    const date = new Date();
    const [year, month, day] = string.split('-');
    if (+year <= 0) throw new Error('Invalid year in ISO');
    date.setUTCFullYear(+year);
    if (+month < 1 || +month > 12) throw new Error('Invalid month in ISO');
    date.setUTCMonth(+month - 1);
    if (+day < 1 || +day > 31) throw new Error('Invalid day in ISO');
    date.setUTCDate(+day);
    if (date.getUTCDate() != +day) throw new Error('Invalid day of month');
    return date;
};

export const toISO = (date: Date): string => {
    const year = ('000' + date.getUTCFullYear()).slice(-4);
    const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + date.getUTCDate()).slice(-2);
    return [year, month, day].join('-');
};

export const toLocalString = (date: Date, locale: string): string => {
    const formatter = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    return formatter.format(date);
};

const _buildLocalDateRegexp = (locale: string): RegExp => {
    const formatter = new Intl.DateTimeFormat(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    const parts = formatter.formatToParts(new Date());
    let dateRegExp = '';
    parts.forEach((part) => {
        switch (part.type) {
            case 'literal':
                dateRegExp += part.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                break;
            default:
                dateRegExp += `(?<${part.type}>\\d{${part.value.length}})`;
        }
    });
    return new RegExp(dateRegExp);
};

export const fromLocalString = (string: string, locale: string): Date => {
    const dateParser = _buildLocalDateRegexp(locale);
    const match = string.match(dateParser);
    if (match && match.groups) {
        const { year, month, day } = match.groups;
        const date = fromISO(`${year}-${month}-${day}`);
        return date;
    } else {
        throw new Error('Date does not match locale format');
    }
};
