import { MediaFragment } from '@graphql/generated/graphql';

export interface Asset {
    hash?: MediaFragment['hash'];
    ext?: MediaFragment['ext'];
}

export function getStrapiAssetUrl(asset?: Asset): string | undefined {
    if (!asset?.ext || !asset.hash) return undefined;
    return process.env.NEXT_PUBLIC_AWS_CF_URL! + '/' + asset.hash + asset.ext;
}
