import CalloutMarkdown from '@components/markdownComponents/calloutMarkdown';
import ImageMarkdown from '@components/markdownComponents/imageMarkdown';
import TableMarkdown from '@components/markdownComponents/tableMarkdown';
import { StyledProps, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import StrapiImage, {
    PropsType as StrapiImagePropsType,
} from '@lib/image/strapiImage';
import {
    ComponentProps,
    FC,
    CSSProperties,
    PropsWithChildren,
    memo,
} from 'react';
import { useRemarkSync } from 'react-remark';
import remarkGfm from 'remark-gfm';
import remarkUnwrapImages from 'remark-unwrap-images';

import { Body, Heading1, Heading2, Heading3, Heading4, Heading5 } from './text';

type Props = {
    markdown: string;
    textColor?: string;
    ImageComponent?: FC<StrapiImagePropsType>;
    style?: CSSProperties;
};

const Span = styled.span``;

const Div = styled.div<StyledProps<{ textColor: string }>>`
    * {
        color: ${({ textColor }) =>
            typeof textColor === 'string' ? textColor : 'inherit'};
    }
    & > ul,
    ol {
        padding-inline-start: ${(props) => props.theme.spacing.md};
        margin-bottom: ${(props) => props.theme.spacing.md};
        font: ${(props) => props.theme.fonts.body};
        li {
            margin-bottom: ${(props) => props.theme.spacing.xs};
        }
    }
    & > ul {
        list-style: disc;
    }
    & > ol {
        list-style: decimal;
    }
    a {
        color: inherit;
        text-decoration: underline;
        word-break: break-word;
    }
    & > h1 {
        margin-bottom: ${(props) => props.theme.spacing.md};
    }
    & > h2 {
        margin-bottom: ${(props) => props.theme.spacing.sm};
    }
    & > h3 {
        margin-top: ${(props) => props.theme.spacing.xxs};
        margin-bottom: ${(props) => props.theme.spacing.xs};
    }
    & > p {
        margin-bottom: ${(props) => props.theme.spacing.sm};
    }
    p img,
    ul img,
    ol img {
        // specific case for inline images
        position: relative;
        display: inline;
        left: 5px;
        top: 5px;
        max-height: 2.5ch;
        margin-inline: ${(props) => props.theme.spacing.xs};
        margin-block: 0;
        border-radius: 0;
    }
    & > :last-child {
        margin-bottom: 0;
    }
`;

const DefaultStrapiImage = styled(StrapiImage)``;

const Markdown: FC<Props> = ({
    markdown,
    textColor,
    ImageComponent = DefaultStrapiImage,
    style,
}) => {
    const theme = useTheme();

    if (!textColor) textColor = theme.palette.text.primary;

    const reactContent = useRemarkSync(markdown, {
        //@ts-ignore
        remarkPlugins: [remarkUnwrapImages, remarkGfm],
        rehypeReactOptions: {
            components: {
                h1: (props: ComponentProps<'h1'>) => <Heading1 {...props} />,
                h2: (props: ComponentProps<'h2'>) => <Heading2 {...props} />,
                h3: (props: ComponentProps<'h3'>) => <Heading3 {...props} />,
                h4: (props: ComponentProps<'h4'>) => <Heading4 {...props} />,
                h5: (props: ComponentProps<'h5'>) => <Heading5 {...props} />,
                img: (props: ComponentProps<'img'>) =>
                    props.src ? (
                        <ImageMarkdown
                            src={props.src}
                            ImageComponent={ImageComponent}
                        />
                    ) : null,
                a: (props: ComponentProps<'a'>) => (
                    <a {...props} target='_blank' rel='noreferrer'>
                        {props.children}
                    </a>
                ),
                // eslint-disable-next-line
                p: (props: PropsWithChildren<ComponentProps<'p'>>) => {
                    if (
                        Array.isArray(props?.children) &&
                        typeof props?.children?.[0] === 'string' &&
                        props.children[0].includes('p(callout')
                    ) {
                        const array: JSX.Element[] = [];
                        props.children.map((child, index) => {
                            if (index === 0 && typeof child === 'string') {
                                child = child.split('-|-')[1];
                            }
                            array.push(child);
                        });
                        return (
                            <CalloutMarkdown
                                callout={props?.children?.[0].split('-|-')[0]}
                            >
                                {array.map((el, index) => (
                                    <Span key={index}>{el}</Span>
                                ))}
                            </CalloutMarkdown>
                        );
                    } else {
                        return (
                            <Body color={textColor} style={style}>
                                {props.children}
                            </Body>
                        );
                    }
                },
                table: (props: ComponentProps<'table'>) => {
                    return <TableMarkdown>{props.children}</TableMarkdown>;
                },
            },
        },
    });

    return <Div textColor={textColor}>{reactContent}</Div>;
};

export default memo(Markdown);
