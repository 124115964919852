import styled from '@emotion/styled';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
    TrackEvent,
} from '@enums';
import {
    Enum_Componentlinksproductlinks_Linksto,
    ModuleDisplayType,
} from '@graphql/generated/graphql';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { MouseEventHandler } from 'react';

type PropsType = {
    children: React.ReactNode;
    href: string;
    className?: string;
    destination_name: string;
    destination_category:
        | OutboundLinkDestinationCategory
        | Enum_Componentlinksproductlinks_Linksto;

    context: OutboundLinkContext;
    shape: OutboundLinkShape | ModuleDisplayType;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const A = styled.a`
    text-decoration: none;
    pointer-events: auto;
`;

const NewTabLink = ({
    children,
    href,
    className,
    destination_name,
    onClick,
    ...outboundLinkTrackingProps
}: PropsType) => {
    const { track } = useAnalytics();

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.stopPropagation();
        track(TrackEvent.OutboundLink, {
            category: 'click',
            destination: href,
            destination_name: destination_name,
            ...outboundLinkTrackingProps,
        });

        if (onClick) {
            onClick(e);
        }
    };

    return (
        <A
            href={href}
            target={href == 'javascript: void(0)' ? undefined : '_blank'}
            rel='noopener'
            className={className}
            onClick={handleClick}
            aria-label={destination_name || 'Outbound Link'}
        >
            {children}
        </A>
    );
};

export default NewTabLink;
