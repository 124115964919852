import {
    DefaultApi,
    CustomerInput,
    FileUpload,
} from '@hello-pimster/pimster-crm-sdk';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Axios from 'axios';
import { useRouter } from 'next/router';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { createApiCrmClient, ResponseInterceptor } from '../client';
import {
    CHAT_CONTROLLER_COMPLETION_PATH,
    COOKIE_ID_HEADER,
    CUSTOMER_INPUT_CONTROLLER_ATTACHMENT_PATH,
    LOCALE_HEADER,
} from '../constants';
import { FetchClient } from '../types/FetchClient';
import { FileUploadClient } from '../types/FileUploadClient';
import { eventSourceFactory } from '../utils/eventSourceFactory';
import { fileUploadFactory } from '../utils/fileUploadFactory';

interface IApiCrmContext {
    client: DefaultApi;
    fetchClient: FetchClient;
    fileUploadClient: FileUploadClient;
    headers: Partial<Axios.AxiosRequestHeaders>;
    setHeaders: (newHeaders: Axios.AxiosRequestHeaders) => void;
    addHeaders: (newHeaders: Partial<Axios.AxiosRequestHeaders>) => void;
    addInterceptor: (newInterceptor: ResponseInterceptor) => void;
}

export const ApiCrmContext = createContext<IApiCrmContext>(
    {} as IApiCrmContext
);

interface Props {
    children: ReactNode;
}

const ApiCrmProvider = ({ children }: Props) => {
    const [headers, setHeaders] = useState<Partial<Axios.AxiosRequestHeaders>>(
        {}
    );
    const { analytics } = useAnalytics();
    const { locale } = useRouter();
    const [interceptors, setInterceptors] = useState<ResponseInterceptor[]>([]);

    /**
     * Axios API
     */
    const userIdInterceptor: ResponseInterceptor = useMemo(
        () => ({
            fulfilled: (res: Axios.AxiosResponse<CustomerInput>) => {
                const { UUID } = res.data.customerProfile!.customerIdentity!;
                analytics.identify(UUID);
                return res;
            },
            condition: (res: Axios.AxiosRequestConfig<CustomerInput>) => {
                /// Only run when receiving a customer identity
                return !!res.data?.customerProfile?.customerIdentity?.UUID;
            },
        }),
        [analytics]
    );

    /**
     * Swagger Generated API
     */
    const client = useMemo(() => {
        return createApiCrmClient(headers, [
            userIdInterceptor,
            ...interceptors,
        ]);
    }, [headers, userIdInterceptor, interceptors]);

    /**
     * Fetch API
     */
    const fetchClient = useMemo(
        () => ({
            agentControllerCompletion: (
                prompt: string,
                agentId: number,
                chatId: string,
                onResponse?: (response: Response) => void
            ) => {
                const fn = eventSourceFactory(
                    CHAT_CONTROLLER_COMPLETION_PATH(chatId)
                );

                return fn(
                    {
                        headers,
                        searchParams: new URLSearchParams({
                            prompt,
                        }),
                    },
                    onResponse
                );
            },
        }),
        [headers]
    );

    /**
     * File Upload API
     */
    const fileUploadClient: FileUploadClient = useMemo(() => {
        const axiosHeaders = new Axios.AxiosHeaders(headers);
        const customerInputControllerAttachmentFn = fileUploadFactory(
            CUSTOMER_INPUT_CONTROLLER_ATTACHMENT_PATH
        );

        return {
            customerInputControllerAttachment: (
                data: FileUpload,
                options?: Axios.AxiosRequestConfig<FormData>
            ): Promise<Axios.AxiosResponse> =>
                customerInputControllerAttachmentFn(data, {
                    ...options,
                    headers: { ...options?.headers, ...axiosHeaders },
                }),
        };
    }, [headers]);

    /**
     * Headers Updates
     */
    // Should be replaced by a reducer
    const addHeaders: IApiCrmContext['addHeaders'] = (newHeaders) => {
        setHeaders((headers) => ({ ...headers, ...newHeaders }));
    };

    useEffect(() => {
        const setCookieId = async () => {
            const user = await analytics?.user();
            if (user) {
                const cookieId = user.anonymousId();
                addHeaders({ [COOKIE_ID_HEADER]: cookieId as string });
            } else {
                console.error(
                    '[PIMSTER_CRM] No user found for CRM initialization'
                );
            }
        };
        setCookieId();
    }, [analytics]);

    useEffect(() => {
        addHeaders({ [LOCALE_HEADER]: locale });
    }, [locale]);

    const addInterceptor = useCallback(
        (newInterceptor: ResponseInterceptor) => {
            setInterceptors((interceptors) => [
                ...interceptors,
                newInterceptor,
            ]);
        },
        []
    );

    /**
     * Query Client Provider
     */
    const queryClient = useMemo(() => {
        return new QueryClient();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ApiCrmContext.Provider
                value={{
                    client,
                    fetchClient,
                    fileUploadClient,
                    headers,
                    setHeaders,
                    addHeaders,
                    addInterceptor,
                }}
            >
                {children}
            </ApiCrmContext.Provider>
        </QueryClientProvider>
    );
};

export default ApiCrmProvider;

export const useApiCrm = () => useContext(ApiCrmContext);
