import { FORM } from '@constants';
import { getInitialValues, getValidationSchema } from '@utils/form/custom';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useMemo,
} from 'react';

import { useSurveyContext } from './SurveyContext';

interface SurveyPageContextProviderProps extends PropsWithChildren {}

type SurveyPageContextType = SurveyPageContextProviderProps;

const partialInitialContext: SurveyPageContextType = {};

export const SurveyPageContext = createContext<SurveyPageContextType>(
    partialInitialContext
);

export const SurveyPageContextProvider: FC<SurveyPageContextProviderProps> = ({
    children,
}) => {
    const {
        surveyForm,
        methods: { onSurveySuccess },
    } = useSurveyContext();
    const { t } = useTranslation(FORM);

    const initialValues = useMemo(() => {
        return getInitialValues(surveyForm ?? []);
    }, [surveyForm]);

    const validationSchema = useMemo(() => {
        return getValidationSchema({
            fields: surveyForm ?? [],
            t,
        });
    }, [surveyForm, t]);

    const initialContext: SurveyPageContextType = {
        ...partialInitialContext,
    };

    return (
        <SurveyPageContext.Provider value={initialContext}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={onSurveySuccess}
            >
                {children}
            </Formik>
        </SurveyPageContext.Provider>
    );
};

export const useSurveyPageContext = () =>
    useContext<SurveyPageContextType>(SurveyPageContext);
