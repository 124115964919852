export const TRANSITION_DURATION_IN_SECONDS = 0.25;
export const TRANSITION_DURATION_IN_MS = TRANSITION_DURATION_IN_SECONDS * 1000;
export const SECONDS_PER_HOUR = 60 * 60;
export const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;
export const IS_NOT_ANONYMOUS_REQUIREMENTS = ['email', 'phoneNumber'];

/**
 * Represent one megabyte in decimal (bytes)
 */
export const ONE_MB_IN_DECIMAL = 1000000;
