import styled from '@emotion/styled';
import { ComponentFormFieldsInput } from '@graphql/generated/graphql';

type PropsType = {
    elt: ComponentFormFieldsInput;
};

export const ModalInput = styled.input<{ isInvalid?: boolean }>`
    background: ${({ theme }) => theme.palette.background.paper};
    border-radius: ${({ theme }) => theme.shape.radius.default};
    font: ${(props) => props.theme.fonts.body};
    border: ${({ theme }) => theme.borders[1]};
    border-color: ${({ isInvalid }) => (isInvalid ? 'red' : undefined)};
    width: 100%;
    padding-block: ${({ theme }) => theme.spacing.xs};
    word-break: break-word;
    padding-left: 1rem;
    &:focus {
        outline: none;
    }
    pointer-events: auto;
`;

export const Label = styled.label`
    display: inline-block;
    text-align: left;
    font: ${(props) => props.theme.fonts.body};
    line-height: normal;
    font-weight: bold;
    padding-left: 0.5rem;
`;

export const Mandatory = styled.span`
    color: red;
`;

const TextInput = ({ elt }: PropsType) => {
    const placeholder = elt.Placeholder ?? '';
    return (
        <>
            <Label>
                {elt.Label}
                <Mandatory>
                    {elt.IsRequired && elt.Label ? '*' : null}
                </Mandatory>
            </Label>
            <ModalInput
                placeholder={
                    placeholder + (elt.IsRequired && !elt.Label ? '*' : '')
                }
                name={elt.Name!}
                required={elt.IsRequired}
                defaultValue={elt.DefaultValue!}
            />
        </>
    );
};

export default TextInput;
