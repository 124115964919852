import { FullPageWrapper } from '@components/containers/fullPage';
import {
    LEAF_ROUTES,
    ROUTE_SEARCH,
    ROUTE_STORY,
    TRANSITION_DURATION_IN_SECONDS,
} from '@constants';
import styled from '@emotion/styled';
import localeDirection from '@utils/locale/direction';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';

interface Props {
    children: ReactNode;
}

const AnimatedDiv = styled(motion.div)`
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    @media (min-width: 748px) {
        border-radius: ${({ theme }) => theme.shape.radius.default};
    }
`;

const rightMotion: Variants = {
    initial: { x: '-100%' },
    animate: {
        x: 0,
        transition: { duration: TRANSITION_DURATION_IN_SECONDS },
    },
    exit: {
        x: '100%',
        transition: { duration: TRANSITION_DURATION_IN_SECONDS },
    },
};

const leftMotion: Variants = {
    initial: { x: '100%' },
    animate: {
        x: 0,
        transition: { duration: TRANSITION_DURATION_IN_SECONDS },
    },
    exit: {
        x: '-100%',
        transition: { duration: TRANSITION_DURATION_IN_SECONDS },
    },
};

const AnimationLayout = ({ children }: Props) => {
    const router = useRouter();
    const isRtl = localeDirection(router.locale) === 'rtl';
    const [variant, setVariant] = useState<'backward' | 'forward'>();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleRouteChange = (destinationUrl: string) => {
            if (
                router.pathname === ROUTE_STORY &&
                destinationUrl.includes('next')
            ) {
                return setVariant('forward');
            }

            if (router.pathname === ROUTE_SEARCH) {
                if (destinationUrl.includes('story')) {
                    setVariant('forward');
                } else {
                    setVariant('backward');
                }

                return;
            }

            if (
                LEAF_ROUTES.includes(router.pathname) ||
                destinationUrl === '/' ||
                destinationUrl === `/${router.locale}`
            ) {
                setVariant('backward');
            } else {
                setVariant('forward');
            }
        };

        const handleRouteComplete = () => {
            ref.current?.scrollTo(0, 0);
        };

        router.events.on('routeChangeStart', handleRouteChange);
        router.events.on('routeChangeComplete', handleRouteComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
            router.events.off('routeChangeComplete', handleRouteComplete);
        };
    }, [router]);

    return (
        <FullPageWrapper ref={ref}>
            <AnimatePresence mode='popLayout' initial={false}>
                <AnimatedDiv
                    key={router.asPath}
                    variants={
                        variant === 'backward'
                            ? isRtl
                                ? leftMotion
                                : rightMotion
                            : variant === 'forward'
                              ? isRtl
                                  ? rightMotion
                                  : leftMotion
                              : undefined
                    }
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    transition={{ ease: 'linear' }}
                >
                    {children}
                </AnimatedDiv>
            </AnimatePresence>
        </FullPageWrapper>
    );
};

export default AnimationLayout;
