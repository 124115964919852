import {
    Enum_Modals_Trigger,
    ModalFragment,
    Modals,
    useGetModalsByCompanySlugQuery,
} from '@graphql/generated/graphql';
import { Queue } from '@utils/queue';
import { useRouter } from 'next/router';
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from 'react';

import { ActionType, modalsReducer } from './modalsReducer';


export type TriggerEvent = {
    event: Enum_Modals_Trigger;
    filters: Record<string, unknown>;
};

interface Props {
    eventQueue: Queue<TriggerEvent>;
}

const ModalContext = createContext<{
    modals?: ModalFragment[];
    currentModal?: ModalFragment;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    triggerEvent: (event: TriggerEvent) => void;
}>({
    modals: [],
    currentModal: undefined,
    isOpen: false,
    onOpen: () => {},
    onClose: () => {},
    triggerEvent: () => {},
});

const ModalProvider = ({ children, eventQueue }: PropsWithChildren<Props>) => {
    const router = useRouter();
    const company = router.query?.company as string;

    const { data, loading } = useGetModalsByCompanySlugQuery({
        variables: { companySlug: company, locale: router.locale! },
    });

    // Modal reducer
    const [{ isOpen, modals, currentModal }, dispatch] = useReducer(
        modalsReducer,
        {
            isOpen: false,
            modals: undefined,
            currentModal: undefined,
            queue: eventQueue,
        }
    );

    useEffect(() => {
        if (loading || !data?.companies || data.companies.length == 0) return;
        const modals = data.companies[0].Modals || [];
        const noNullModal = modals.filter((elt) => elt !== null);
        dispatch({
            type: ActionType.SET_MODALS,
            modals: noNullModal as Modals[],
        });
    }, [loading, data]);

    const triggerEvent = useCallback(
        (evnt: TriggerEvent) => {
            dispatch({
                type: ActionType.EVENT_TRIGGER,
                event: evnt.event,
                filters: evnt.filters,
            });
        },
        [dispatch]
    );

    const processQueuedEvent = useCallback(() => {
        if (isOpen) return;
        const nextEvent = eventQueue.dequeue();
        if (nextEvent) triggerEvent(nextEvent);
        // After process, process the next event if needed
        if (!eventQueue.isEmpty()) processQueuedEvent();
    }, [eventQueue, isOpen, triggerEvent]);

    useEffect(() => {
        if (!modals) return;
        // Attach event listener to trigger processing upon receiving event
        const { removeEventListener } =
            eventQueue.addEventListener(processQueuedEvent);
        return removeEventListener;
    }, [modals, eventQueue, processQueuedEvent]);

    useEffect(() => {
        // Upon receiving modal or upon modal close, start processing queued events
        if (!modals || isOpen) return;
        if (!eventQueue.isEmpty()) processQueuedEvent();
    }, [eventQueue, isOpen, modals, processQueuedEvent]);

    const onOpen = () => {
        dispatch({
            type: ActionType.SET_OPEN,
            isOpen: true,
        });
    };

    const onClose = () => {
        dispatch({ type: ActionType.SET_OPEN, isOpen: false });
    };

    return (
        <ModalContext.Provider
            value={{
                modals,
                currentModal,
                isOpen,
                onOpen,
                onClose,
                triggerEvent,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

const useModals = () => useContext(ModalContext);

export { ModalProvider, useModals };
