/**
 * Builds a slug from a UID by extracting the part after the last '::'.
 * @param UID The UID string.
 * @returns The extracted slug.
 */
export const buildSlugFromUID = (UID?: string): string | undefined => {
    if (!UID) return;
    const parts = UID.split('::');
    return parts[parts.length - 1];
};
