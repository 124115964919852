import { RenderFormElementComponent } from '@components/form/custom/RenderFormElementComponent';
import styled from '@emotion/styled';
import { OutboundLinkContext } from '@enums';
import { Form } from 'formik';

import { useSurveyContext } from '../contexts/SurveyContext';

const VStack = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.sm};
`;

const SurveyPageForm: React.FC = () => {
    const { currentForm, survey } = useSurveyContext();

    return (
        <Form>
            <VStack>
                {currentForm?.map((elt, index) => (
                    <RenderFormElementComponent
                        key={index}
                        formElementComponent={elt}
                        companyName={survey?.Company?.Name || ''}
                        outboundLinkContext={OutboundLinkContext.SurveyPage}
                    />
                ))}
            </VStack>
        </Form>
    );
};

export default SurveyPageForm;
