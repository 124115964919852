/**
 * Get distinct value of array of object based on an accessor (a property for example).
 * @param accessor Callback used to retrieve the unique accessor of object.
 * @param arr Array of object
 * @returns Distinct array of
 */
export const distinctArray = <T = object>(
    accessor: (obj: T) => string | number,
    arr: T[]
): T[] => {
    const map = new Map<string | number, T>(
        arr.map((obj) => [accessor(obj), obj])
    );

    return Array.from(map.values());
};
