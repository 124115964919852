import { Message } from '@hello-pimster/pimster-crm-sdk';
import {
    ParsedEvent,
    ReconnectInterval,
    createParser,
} from 'eventsource-parser';
import { Observable, Subject } from 'rxjs';

export type ServerEvent<T = Message> = Omit<ParsedEvent, 'data'> & { data: T };

export const eventSourceFactory =
    <T = Message>(url: URL | string) =>
    (
        options: RequestInit & { searchParams?: URLSearchParams },
        onResponse?: (response: Response) => void
    ): Observable<ServerEvent<T>> => {
        const fullPath = new URL(url, process.env.NEXT_PUBLIC_API_CRM_URL);

        options.searchParams?.forEach((value, key) =>
            fullPath.searchParams.append(key, value)
        );
        const eventSubject = new Subject<ServerEvent<T>>();

        fetch(fullPath, options)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(
                        `[PIMSTER_CRM] Event source server responded ${res.status} ${res.statusText}`
                    );
                }
                onResponse?.(res);
                return res.body;
            })
            .then((body) => {
                if (!body) {
                    throw new Error(
                        '[PIMSTER_CRM] Server responded empty body'
                    );
                }
                const reader = body.getReader();
                const decoder = new TextDecoder('utf-8');
                const parser = createParser(
                    (event: ParsedEvent | ReconnectInterval) => {
                        switch (event.type) {
                            case 'event':
                                const transformedEvent = {
                                    ...event,
                                    data: JSON.parse(event.data),
                                };
                                eventSubject.next(transformedEvent);
                                break;
                            case 'reconnect-interval':
                                break;
                        }
                    }
                );
                const processStream = async () => {
                    while (true) {
                        const { done, value } = await reader.read();

                        if (done) break;

                        try {
                            const chunk = decoder.decode(value);
                            parser.feed(chunk);
                        } catch (err) {
                            eventSubject.error(err);
                        }
                    }
                    eventSubject.complete();
                    return true;
                };

                return processStream();
            })
            .catch((err) => {
                eventSubject.error(err);
            });

        return eventSubject.asObservable();
    };
