/**
 * FORM CONSTANTS
 * Define constants variables about forms.
 *
 * TODO:
 *  - Define maximum file size
 *  - Define default file types
 */
import { AcceptedFileTypes } from '@components/utils/inputElements/FileInput';

/**
 * Maximum size of uploaded files.
 * In decimal (bytes).
 *
 * Current value: 10 MB
 */
export const FORM_FILE_UPLOAD_DEFAULT_MAX_SIZE = 10000000;

/**
 * Accepted files types.
 * Keys represents [MIME Types](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types).
 * Values are array of possible extensions for the same MIME Type.
 *
 * Example for JPEG image files: { 'application/jpeg': ['.jpg', '.jpeg'] }
 *  - MIME Type: application/jpeg
 *  - Extensions: .jpg or .jpeg
 */
export const FORM_FILE_UPLOAD_DEFAULT_ACCEPTS: AcceptedFileTypes = {
    'image/png': ['.png'],
    'application/pdf': ['.pdf'],
    'application/jpeg': ['.jpg', '.jpeg'],
};

/**
 * Prefix of file attachment fields from data body of the form.
 */
export const FORM_FILE_UPLOAD_ATTACHMENT_PREFIX = '$attachment-';
