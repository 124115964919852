import styled from '@emotion/styled';

export const Centered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;

    img {
        max-width: 100%;
        max-height: 200px;
        border-radius: ${({ theme }) => theme.shape.radius.default};
        margin-block: ${({ theme }) => theme.spacing.sm};
    }
`;
