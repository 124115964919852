import defaultTheme from './defaultTheme';

export type Space = keyof typeof defaultTheme.spacing;

export const isSpace = (value: unknown): value is Space => {
    if (typeof value !== 'string') return false;

    return Object.keys(defaultTheme.spacing).includes(value);
};

export type BackgroundColor = keyof typeof defaultTheme.palette.background;

type TypographyKeys = keyof typeof defaultTheme.typography;
export type ExcludedTypographyKeys =
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'lineHeight'
    | 'fontStyle';

export type TypographyVariant = {
    [K in TypographyKeys]: K extends ExcludedTypographyKeys | keyof string
        ? never
        : K;
}[TypographyKeys];

export interface TypographyElement {
    fontFamily: string;
    fontSize: string;
    fontWeight: number;
    lineHeight: string;
    fontStyle: string;
    textTransform?: string;
}

export type Fonts = Record<'font' | TypographyVariant, string>;

export type CustomTheme = typeof defaultTheme & {
    fonts: Fonts;
};

// recustive partial with all nested properties from object
type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
          ? RecursivePartial<T[P]>
          : T[P];
};

export type CompanyTheme = RecursivePartial<CustomTheme>;
