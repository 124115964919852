import { ROUTE_ONBOARDING } from '@constants';
import { useGetLightOnboardingByCompanyAndProductQuery } from '@graphql/generated/graphql';
import { getLastVisited, updateLastVisited } from '@utils/lastVisited';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

interface Props {
    productSlug: string;
}

export const useOnboarding = ({ productSlug }: Props) => {
    const {
        query: { company, skip_onboarding },
        locale,
        push,
        isReady,
    } = useRouter();

    const { data, loading } = useGetLightOnboardingByCompanyAndProductQuery({
        variables: {
            companySlug: company as string,
            productSlug,
            locale,
        },
    });

    const [isRedirecting, setIsRedirecting] = useState(false);

    const isLoading = useMemo(
        () => !isReady || loading || isRedirecting,
        [isReady, loading, isRedirecting]
    );

    const hasOnboardingBeenSeen = Boolean(
        getLastVisited(company)[`${productSlug}_onboarding`]
    );

    const setOnboardingHasBeenSeen = () => {
        updateLastVisited(company, `${productSlug}_onboarding`);
    };

    const productHasOnboarding = useMemo(
        () => Boolean(data?.products?.[0]?.Onboarding),
        [data]
    );

    const shouldRedirectToOnboarding = useMemo(() => {
        if (hasOnboardingBeenSeen || skip_onboarding === 'true') {
            return false;
        }

        return productHasOnboarding;
    }, [productHasOnboarding, hasOnboardingBeenSeen, skip_onboarding]);

    const redirectToOnboarding = (): void => {
        setIsRedirecting(true);
        push(
            {
                pathname: ROUTE_ONBOARDING,
                query: { company, product: productSlug },
            },
            `/${productSlug}/onboarding`
        );
    };

    return {
        redirectToOnboarding,
        productHasOnboarding,
        shouldRedirectToOnboarding,
        setOnboardingHasBeenSeen,
        hasOnboardingBeenSeen,
        isLoading,
    };
};
