import LinkButton from '@components/utils/cta/linkButton';
import { Caption } from '@components/utils/text';
import { COMMON } from '@constants';
import styled from '@emotion/styled';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';
import {
    AdditionalProductFragment,
    RedirectionType,
} from '@graphql/generated/graphql';
import StrapiImage from '@lib/image/strapiImage';
import { getStrapiAssetUrl } from '@lib/strapi';
import { VStack } from '@lib/uikit';
import { useTranslation } from 'next-i18next';

type Props = { additionalProduct: AdditionalProductFragment };

const HStack = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.md};
`;

const SStrapiImage = styled(StrapiImage)`
    object-fit: cover;
    border-radius: ${({ theme }) => theme.shape.radius.default};
    width: 8rem;
    height: 8rem;
`;

const Title = styled.p`
    font: ${({ theme }) => theme.fonts.heading3};
    color: ${({ theme }) => theme.palette.text.primary};
`;

const SLinkButton = styled(LinkButton)`
    margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const AdditionalProductModalPreview = ({ additionalProduct }: Props) => {
    const { t } = useTranslation(COMMON);

    if (!additionalProduct.Url) return null;

    return (
        <HStack>
            <SStrapiImage
                sizes='8rem'
                src={getStrapiAssetUrl(additionalProduct.Image)}
                formats={additionalProduct.Image?.formats}
                alt={
                    additionalProduct.Image?.alternativeText ??
                    additionalProduct.Name
                }
            />
            <VStack spacing='xxs'>
                <Title>{additionalProduct.Name}</Title>
                {additionalProduct.Description && (
                    <Caption>{additionalProduct.Description}</Caption>
                )}
                <SLinkButton
                    type={RedirectionType.Outside}
                    url={additionalProduct.Url}
                    text={t('purchase')}
                    destinationName={additionalProduct.Name}
                    destinationCategory={
                        OutboundLinkDestinationCategory.AdditionalProduct
                    }
                    context={OutboundLinkContext.Modal}
                    shape={OutboundLinkShape.Button}
                    buttonSize='small'
                />
            </VStack>
        </HStack>
    );
};
