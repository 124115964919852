import { attachmentFieldKey } from '@components/utils/inputElements/FileInput';
import { FormFieldOrConsent } from '@types';

export const getFieldKey = (field: FormFieldOrConsent, index: number) => {
    switch (field.__typename) {
        case 'ComponentFormFieldsInput':
        case 'ComponentFormFieldsDateInput':
        case 'ComponentFormFieldsSingleSelect':
            return field.Name;

        case 'ComponentFormFieldsFileInput':
            return attachmentFieldKey(field.Name);

        case 'ComponentConsentsEmail':
            return 'emailOptin';

        default:
            return `field-${index}`;
    }
};
