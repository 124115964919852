import styled from '@emotion/styled';
import { FormControl, getInputStyling } from '@lib/uikit';
import { isString } from 'formik';
import { ComponentProps, FC, useEffect, useState } from 'react';

import { SelectProps } from './types';


const ARROW_DOWN_IMG =
    'data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E';

const StyledSelect = styled.select`
    padding-left: 1rem;
    ${getInputStyling};
    font: ${(props) => props.theme.fonts.body};

    appearance: none;
    background-image: url(${ARROW_DOWN_IMG});
    background-repeat: no-repeat;
    background-position: right 1.5rem top 50%;
    background-size: 0.65rem auto;

    &.placeholder-shown {
        color: grey;
    }

    &.placeholder-hidden {
        color: black;
    }
`;

type SingleSelectProps = Omit<ComponentProps<'select'>, 'children'> &
    SelectProps & {
        isRequired?: boolean;
        placeholder?: string;
    };

export const SingleSelect: FC<SingleSelectProps> = ({
    choices,
    isRequired,
    label,
    errorMessage,
    ...rest
}) => {
    const [isPlaceholderShown, setPlaceholderShown] = useState(true);

    useEffect(() => {
        if (rest.value) {
            setPlaceholderShown(false);
        } else {
            setPlaceholderShown(true);
        }
    }, [rest.value]);

    return (
        <FormControl
            name={rest.name}
            label={label}
            errorMessage={errorMessage}
            isRequired={isRequired}
        >
            <StyledSelect
                {...rest}
                className={
                    isPlaceholderShown
                        ? 'placeholder-shown'
                        : 'placeholder-hidden'
                }
                onChange={(e) => {
                    if (e.target.value) {
                        setPlaceholderShown(false);
                    } else {
                        setPlaceholderShown(true);
                    }
                    if (rest.onChange) {
                        rest.onChange(e);
                    }
                }}
                defaultValue=''
            >
                <option style={{ color: 'grey' }} value='' disabled>
                    {rest.placeholder}
                    {isRequired && '*'}
                </option>
                {choices?.map((value, index) => (
                    <option
                        key={index + '-option-select'}
                        value={isString(value) ? value : value.value}
                    >
                        {isString(value) ? value : value.title}
                    </option>
                ))}
            </StyledSelect>
        </FormControl>
    );
};
