import { Enum_Componentformfieldsinput_Type } from '@graphql/generated/graphql';
import {
    FormFieldOrConsent,
    FormPageElementsDynamicZone,
    SinglePageFormElementsDynamicZone,
} from '@types';
import { isPhoneValid } from '@utils/phone';
import { TFunction } from 'next-i18next';
import * as yup from 'yup';

import { getFieldKey } from './getFieldKey';
import { isFormFieldOrConsent } from './guards';


type YupSchemaShape = ReturnType<typeof yup.object>;

type GetValidationSchema = {
    fields: SinglePageFormElementsDynamicZone | FormPageElementsDynamicZone;
    t: TFunction;
};

const getFieldYupSchema = (field: FormFieldOrConsent, t: TFunction) => {
    let schema;

    switch (field.__typename) {
        case 'ComponentFormFieldsFileInput':
            schema = yup.object();
            break;
        case 'ComponentFormFieldsDateInput':
            schema = yup.date();
            break;

        case 'ComponentFormFieldsSingleSelect':
            schema = yup.string();
            break;

        case 'ComponentFormFieldsInput':
            switch (field.Type) {
                case Enum_Componentformfieldsinput_Type.Password:
                case Enum_Componentformfieldsinput_Type.Text:
                    schema = yup.string();
                    break;
                case Enum_Componentformfieldsinput_Type.Rating:
                case Enum_Componentformfieldsinput_Type.Number:
                    schema = yup.number();
                    break;
                case Enum_Componentformfieldsinput_Type.Email:
                    schema = yup
                        .string()
                        .email(t('errors.email.format') ?? 'Invalid format');
                    break;
                case Enum_Componentformfieldsinput_Type.Box:
                    schema = yup.bool();

                    if (field.IsRequired) {
                        schema = schema.oneOf([true], t('errors.required'));
                    }

                    break;
                case Enum_Componentformfieldsinput_Type.Tel:
                    schema = yup
                        .string()
                        .test(
                            'is-valid-phone',
                            t('errors.phone.format'),
                            isPhoneValid
                        );
                    break;
            }

            break;

        case 'ComponentConsentsEmail':
            schema = yup.bool().default(field.ImplicitConsent ?? false);

            if (field.IsRequired) {
                schema = schema.oneOf([true], t('errors.required'));
            }

            break;
    }

    if (field.IsRequired && schema) {
        schema = schema.required(t('errors.required'));
    }

    return schema;
};

export const getValidationSchema = ({
    fields,
    t,
}: GetValidationSchema): YupSchemaShape => {
    const finalSchema: Record<string, unknown> = {};

    fields.forEach((field, index) => {
        if (!isFormFieldOrConsent(field)) {
            return;
        }

        const schema = getFieldYupSchema(field, t);

        if (schema) {
            finalSchema[getFieldKey(field, index)] = schema;
        }
    });

    return yup.object().shape(finalSchema as yup.ObjectShape);
};
