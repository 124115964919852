export const getLastVisited = (company: string | string[] | undefined) => {
    try {
        const lastVisited = localStorage.getItem(`${company}_last_visited`);
        if (!lastVisited) return {};
        const parsed = JSON.parse(lastVisited);
        return typeof parsed === 'number' ? {} : parsed;
    } catch (error) {
        return {};
    }
};

export const updateLastVisited = (
    company: string | string[] | undefined,
    property: string
) => {
    const object = getLastVisited(company);
    object[property] = Date.now().toString();
    localStorage.setItem(`${company}_last_visited`, JSON.stringify(object));
    return getLastVisited(company);
};
