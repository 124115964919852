/**
 * Checks if the given object contains any of the specified keys.
 *
 * @param object The object to check for the presence of keys.
 * @param keys An array of keys to check for in the object.
 * @returns A boolean indicating whether the object contains any of the specified keys.
 */
export const objectHasOneOfKeys = (object: object, keys: string[]): boolean => {
    for (const key of keys) {
        if (key in object) {
            return true;
        }
    }

    return false;
};
