import { Theme } from '@emotion/react';
import deepMerge from '@utils/deepMerge';

import { getFonts } from './getFonts';
import defaultTheme from '../theme/defaultTheme';
import { CompanyTheme } from '../theme/types';


export const createCompanyTheme = (companyTheme?: CompanyTheme): Theme => {
    const mergedTheme = deepMerge.withOptions(
        {},
        defaultTheme,
        companyTheme ?? {}
    ) as Theme;
    mergedTheme.fonts = getFonts(mergedTheme.typography);

    return mergedTheme;
};
