import Stars from '@components/utils/rating/stars';
import styled from '@emotion/styled';
import { InputFieldFragment } from '@graphql/generated/graphql';
import { FormControl } from '@lib/uikit';
import { useFormikContext } from 'formik';

type Props = {
    element: InputFieldFragment;
};

const HiddenInput = styled.input`
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
`;

const RatingInput = ({ element }: Props) => {
    const { getFieldProps, errors, setFieldValue } =
        useFormikContext<Record<string, unknown>>();

    const { value } = getFieldProps(`['${element.Name}']`);

    const ratingValue = Number(value) ?? Number(element.DefaultValue);

    const updateRating = (rating: number): void => {
        setFieldValue(`['${element.Name}']`, rating);
    };

    return (
        <FormControl
            label={element.Label}
            isRequired={element.IsRequired}
            errorMessage={errors[element.Name]}
        >
            <>
                <HiddenInput
                    type='radio'
                    name={element.Name}
                    value={ratingValue}
                    checked={true}
                />
                <Stars value={ratingValue} onChange={updateRating} />
            </>
        </FormControl>
    );
};

export default RatingInput;
