import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
    GetCompanyConfigQuery,
    GetCompanyConfigDocument,
} from '@graphql/generated/graphql';
import { isObject } from 'lodash';

import { CompanyTheme } from '../theme/types';

export const PRODUCT_WHITE_LABEL_DEFAULT_VALUE = false;
export const STORY_WHITE_LABEL_DEFAULT_VALUE = true;

export type WhiteLabelConfig = { product: boolean; story: boolean };

export type CompanyConfig = {
    companyTheme: CompanyTheme;
    companyCustomHead: string;
    whiteLabel: WhiteLabelConfig;
};

const isValidWhiteLabelProduct = (
    value: unknown
): value is { product: boolean } => {
    if (!isObject(value)) return false;
    return 'product' in value && typeof value.product === 'boolean';
};

const isValidWhiteLabelStory = (
    value: unknown
): value is { story: boolean } => {
    if (!isObject(value)) return false;
    return 'story' in value && typeof value.story === 'boolean';
};

const sanitizeWhiteLabel = (whiteLabel: unknown): WhiteLabelConfig => {
    return {
        product: isValidWhiteLabelProduct(whiteLabel)
            ? whiteLabel.product
            : PRODUCT_WHITE_LABEL_DEFAULT_VALUE,
        story: isValidWhiteLabelStory(whiteLabel)
            ? whiteLabel.story
            : STORY_WHITE_LABEL_DEFAULT_VALUE,
    };
};

export const getCompanyConfig = async (
    client: ApolloClient<NormalizedCacheObject>,
    companySlug: string,
    locale: string
): Promise<CompanyConfig> => {
    const { data: companyConfigData } =
        await client.query<GetCompanyConfigQuery>({
            query: GetCompanyConfigDocument,
            variables: { slug: companySlug, locale },
        });
    const company = companyConfigData.companies?.[0];
    const companyTheme = (company?.CustomTheme as CompanyTheme) ?? {};
    const companyCustomHead = company?.CustomHead ?? '';
    const whiteLabel = sanitizeWhiteLabel(company?.Config?.whiteLabel);

    return {
        companyTheme,
        companyCustomHead,
        whiteLabel,
    };
};
