// Default routes
export const ROUTE_ARTICLE = '/[company]/[product]/article/[slug]';
export const ROUTE_COMPANY = '/[company]';
export const ROUTE_FAQ = '/[company]/[product]/faq';
export const ROUTE_FLOW = '/[company]/[product]/flow/[slug]';
export const ROUTE_ONBOARDING = '/[company]/[product]/onboarding';
export const ROUTE_PRODUCT = '/[company]/[product]';
export const ROUTE_SEARCH = '/[company]/[product]/search';
export const ROUTE_STORE = '/[company]/[product]/store/[slug]';
export const ROUTE_STORY = '/[company]/[product]/story/[slug]';
export const ROUTE_SUPPORT = '/[company]/[product]/support';
export const ROUTE_SURVEY = '/[company]/[product]/survey/[slug]';

// API routes
export const ROUTE_API_PREVIEW_CLEAR = '/api/preview/clear';
export const ROUTE_API_REVALIDATION = '/api/revalidation';

// Leaf routes
export const LEAF_ROUTES = [
    ROUTE_ARTICLE,
    ROUTE_FAQ,
    ROUTE_FLOW,
    ROUTE_ONBOARDING,
    ROUTE_STORE,
    ROUTE_STORY,
    ROUTE_SUPPORT,
    ROUTE_SURVEY,
];
