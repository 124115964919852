import { useEditContext } from '@components/utils/contexts/EditContext';
import { ROUTE_API_PREVIEW_CLEAR, ROUTE_API_REVALIDATION } from '@constants';
import {
    REVALIDATION_HEADER,
    REVALIDATION_SECRET,
} from '@constants/revalidation';
import styled from '@emotion/styled';
import { colors } from '@lib/uikit/theme/colors';
import { getFullPath } from '@utils/link';
import { useRouter } from 'next/router';

const BottomFixedBar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    color: ${(props) =>
        props.theme.palette.mode === 'light' ? colors.white : colors.black};
    background-color: ${(props) =>
        props.theme.palette.mode === 'light' ? colors.black : colors.white};
    opacity: 0.7;
    padding: ${(props) => props.theme.spacing.sm};
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Label = styled.p`
    color: inherit;
    margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const ExitButton = styled.button`
    background: transparent;
    color: inherit;
    font: ${(props) => props.theme.fonts.caption};
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

interface Props {
    isPreview?: boolean;
}

export const PreviewBar = ({ isPreview }: Props) => {
    const router = useRouter();
    const { isEditMode } = useEditContext();

    if (!isPreview || isEditMode) return null;

    const handleExit = async () => {
        try {
            const response = await fetch(ROUTE_API_PREVIEW_CLEAR);

            if (response.ok) {
                router.reload();
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleRevalidate = async () => {
        try {
            await fetch(ROUTE_API_REVALIDATION, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    [REVALIDATION_HEADER]: REVALIDATION_SECRET,
                },
                body: JSON.stringify({
                    url: getFullPath(router),
                }),
            });
        } catch (err) {
            console.error('[Preview] : Error revalidating page', err);
        }
    };

    return (
        <BottomFixedBar>
            <Label>Preview</Label>
            <ExitButton onClick={handleExit}>Exit</ExitButton>
            <ExitButton onClick={handleRevalidate}>Revalidate Page</ExitButton>
        </BottomFixedBar>
    );
};
