/**
 * Translates a country code to its name in the specified target language.
 *
 * @param countryCode - Country code to translate.
 * @param targetLanguage - Target language for translation.
 * @returns Translated country name or undefined.
 */
export const getTranslatedCountryName = (
    countryCode: string,
    targetLanguage: string
): string | undefined => {
    try {
        const displayNames = new Intl.DisplayNames([targetLanguage], {
            type: 'region',
        });

        const translatedName = displayNames.of(countryCode.toUpperCase());

        return translatedName;
    } catch (error) {
        return undefined;
    }
};

/**
 * Translates a language code to its name in the specified target language.
 *
 * @param languageCode - Language code to translate.
 * @param targetLanguage - Target language for translation.
 * @returns Translated language name or undefined.
 *
 */
export const getTranslatedLanguageName = (
    languageCode: string,
    targetLanguage: string
): string | undefined => {
    try {
        const displayNames = new Intl.DisplayNames([targetLanguage], {
            type: 'language',
        });

        const translatedName = displayNames.of(languageCode);

        return translatedName;
    } catch (error) {
        return undefined;
    }
};
