import LocaleSwitch from '@components/utils/localeSwitch';
import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';
import { useElementSize } from '@hooks/useElementSize';
import {
    DependencyList,
    ForwardedRef,
    ReactNode,
    forwardRef,
    useEffect,
    useRef,
    useState,
} from 'react';

interface Props {
    header: ReactNode;
    body: ReactNode;
    footer?: ReactNode;
    hasFixedHeader?: boolean;
    hasFixedFooter?: boolean;
    withBodyPadding?: boolean;
    footerHasPointerEvents?: boolean;
    scrollToTopDependencies?: DependencyList;
    bodyOverHeader?: boolean;
    locales?: string[];
}

interface HeaderProps {
    hasFixedHeader?: boolean;
}

interface FooterProps {
    hasFixedFooter?: boolean;
    footerHasPointerEvents?: boolean;
}

const Container = styled.div<
    StyledProps<{ headerHeight: number | null; isSafari: boolean }>
>`
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 748px) {
        border-radius: ${({ theme }) => theme.shape.radius.default};
        transform: ${({ isSafari }) => (isSafari ? 'translateZ(0)' : '')};
    }
    scroll-behavior: smooth;
    scroll-padding-top: ${({ headerHeight }) => headerHeight}px;
`;

const Header = styled.header<StyledProps<HeaderProps>>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: ${({ hasFixedHeader }) => !hasFixedHeader || 'sticky'};
    top: 0;
    z-index: 1000;
    @media (min-width: 748px) {
        border-top-left-radius: ${({ theme }) => theme.shape.radius.default};
        border-top-right-radius: ${({ theme }) => theme.shape.radius.default};
    }
`;

const LocaleSwitchDiv = styled.div<{ hasContent?: boolean }>`
    padding-inline: ${(props) => props.theme.spacing.xxs};
    padding-left: 0;
    background: ${(props) => props.theme.palette.background.default};
    border-radius: ${(props) => props.theme.shape.radius.default};
    box-shadow: inset ${(props) => props.theme.shadows.default};
    align-self: end;
    height: auto;
    width: auto;
    align-items: center;
    display: flex;
    margin: ${(props) => props.theme.spacing.sm};
    min-height: ${(props) => (props.hasContent ? 'auto' : '2rem')};
    visibility: ${(props) => (props.hasContent ? 'visible' : 'hidden')};
`;

const HeaderContainer = styled.div`
    width: 100%;
    z-index: 9999;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const Body = styled.div<{
    withBodyPadding?: boolean;
    bodyOverHeader?: boolean;
}>`
    width: 100%;
    padding-inline: ${(props) =>
        props.withBodyPadding ? props.theme.spacing.md : 0};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    z-index: ${({ bodyOverHeader }) => (bodyOverHeader ? 1001 : 0)};
`;

const Footer = styled.footer<StyledProps<FooterProps>>`
    width: 100%;
    padding-top: ${({ theme }) => theme.spacing.xs};
    position: ${({ hasFixedFooter }) => (hasFixedFooter ? 'sticky' : 'static')};
    bottom: 0;
    @media (min-width: 748px) {
        border-bottom-left-radius: ${({ theme }) => theme.shape.radius.default};
        border-bottom-right-radius: ${({ theme }) =>
            theme.shape.radius.default};
    }
    z-index: 1000;
    pointer-events: ${({ footerHasPointerEvents }) =>
        footerHasPointerEvents ? 'auto' : 'none'};
`;

const AppLayout = (
    {
        header,
        body,
        footer,
        hasFixedHeader = true,
        hasFixedFooter = false,
        withBodyPadding = true,
        footerHasPointerEvents = true,
        scrollToTopDependencies,
        bodyOverHeader = false,
        locales = [],
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const [isSafari, setIsSafari] = useState(false);
    const bodyRef = useRef<HTMLDivElement | null>(null);
    const [headerSize, headerRef] = useElementSize();

    useEffect(() => {
        const checkSafari = () => {
            const userAgent = window.navigator.userAgent;
            const safari =
                userAgent.includes('Safari') && !userAgent.includes('Chrome');
            setIsSafari(safari);
        };

        checkSafari();
    }, []);

    useEffect(() => {
        if (scrollToTopDependencies) {
            bodyRef.current?.scrollIntoView();
        }
    }, [scrollToTopDependencies]);

    return (
        <Container
            ref={ref}
            headerHeight={headerSize.height}
            isSafari={isSafari}
        >
            {locales?.length > 1 && (
                <HeaderContainer>
                    <LocaleSwitchDiv hasContent={locales?.length > 1}>
                        <LocaleSwitch subLocales={locales} />
                    </LocaleSwitchDiv>
                </HeaderContainer>
            )}
            <Header hasFixedHeader={hasFixedHeader} ref={headerRef}>
                {header}
            </Header>
            <Body
                ref={bodyRef}
                withBodyPadding={withBodyPadding}
                bodyOverHeader={bodyOverHeader}
            >
                {body}
            </Body>
            <Footer
                hasFixedFooter={hasFixedFooter}
                footerHasPointerEvents={footerHasPointerEvents}
            >
                {footer}
            </Footer>
        </Container>
    );
};

export default forwardRef(AppLayout);
