import Tray from '@components/containers/tray';
import { ModalCloseReason, TrackEvent } from '@enums';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { VStack } from '@lib/uikit';
import { CustomDefaultModalProps } from '@types';
import { useEffect } from 'react';

import { RenderDefaultModalComponent } from './RenderDefaultModalComponent';

interface Props extends Omit<CustomDefaultModalProps, 'render'> {}

export const CustomDefaultModal = ({
    type,
    name,
    UID,
    defaultContent,
    elements,
    isOpen,
    onClose,
}: Props) => {
    const { track } = useAnalytics();

    useEffect(() => {
        if (isOpen) {
            track(TrackEvent.ModalOpen, {
                name,
                modal_uid: UID,
                modal_type: type,
            });
        }
    }, [isOpen, name, type, UID, track]);

    const handleClose = (reason: ModalCloseReason): void => {
        onClose();
        track(TrackEvent.ModalClose, {
            name,
            modal_uid: UID,
            modal_type: type,
            close_reason: reason,
        });
    };

    return (
        <Tray
            isOpen={isOpen}
            onClose={() => handleClose(ModalCloseReason.SimpleClose)}
        >
            <VStack spacing='sm'>
                {defaultContent}
                {elements.map((element, index) => (
                    <RenderDefaultModalComponent
                        key={index}
                        component={element}
                        onCloseModal={handleClose}
                    />
                ))}
            </VStack>
        </Tray>
    );
};
