import NewTabLink from '@components/utils/links/newTabLink';
import { Caption } from '@components/utils/text';
import { CONSENTS } from '@constants';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';
import { TextVariant } from '@lib/uikit/styles/text';
import { Trans, useTranslation } from 'next-i18next';

type Props = {
    outboundLinkContext: OutboundLinkContext;
    textVariant?: TextVariant;
};

const Link = styled(NewTabLink)`
    font: ${(props) => props.theme.fonts.caption};
    text-decoration: underline;
`;

export const CGU = ({
    outboundLinkContext,
    textVariant = TextVariant.Light,
}: Props) => {
    const { t } = useTranslation(CONSENTS);
    const theme = useTheme();

    return (
        <Caption
            color={
                textVariant === TextVariant.Dark
                    ? theme.palette.common.white
                    : theme.palette.text.primary
            }
        >
            <Trans
                i18nKey='cgu'
                t={t}
                components={{
                    cgu: (
                        <Link
                            destination_category={
                                OutboundLinkDestinationCategory.PimsterDocument
                            }
                            context={outboundLinkContext}
                            shape={OutboundLinkShape.InlineText}
                            destination_name='CGU'
                            href={'about:blank'}
                        >
                            any
                        </Link>
                    ),
                }}
            />
        </Caption>
    );
};
