import {
    PRODUCT_WHITE_LABEL_DEFAULT_VALUE,
    STORY_WHITE_LABEL_DEFAULT_VALUE,
    WhiteLabelConfig,
} from '@lib/uikit/utils/getCompanyConfig';
import { createContext, PropsWithChildren, useContext } from 'react';

interface WhiteLabelContext {
    isProductWhiteLabel: boolean;
    isStoryWhiteLabel: boolean;
}

interface Props extends PropsWithChildren {
    whiteLabel?: WhiteLabelConfig;
}

const WhiteLabelContext = createContext<WhiteLabelContext>(
    {} as WhiteLabelContext
);

export const WhiteLabelProvider = ({ children, whiteLabel }: Props) => (
    <WhiteLabelContext.Provider
        value={{
            isProductWhiteLabel:
                whiteLabel?.product ?? PRODUCT_WHITE_LABEL_DEFAULT_VALUE,
            isStoryWhiteLabel:
                whiteLabel?.story ?? STORY_WHITE_LABEL_DEFAULT_VALUE,
        }}
    >
        {children}
    </WhiteLabelContext.Provider>
);

export const useWhiteLabel = () => useContext(WhiteLabelContext);
