import { ModalInput } from '@components/utils/inputElements/textInput';
import NewTabLink from '@components/utils/links/newTabLink';
import MdText from '@components/utils/mdText';
import { Caption } from '@components/utils/text';
import { CONSENTS } from '@constants';
import { StyledProps, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';
import { ComponentConsentsEmail } from '@graphql/generated/graphql';
import { FormErrorMessage } from '@lib/uikit';
import { TextVariant } from '@lib/uikit/styles/text';
import { useFormikContext } from 'formik';
import { Trans, useTranslation } from 'next-i18next';

type Props = {
    element: ComponentConsentsEmail;
    companyName: string;
    outboundLinkContext: OutboundLinkContext;
    textVariant?: TextVariant;
};

const CustomText = styled.div<StyledProps<{ textColor: string }>>`
    display: flex;
    gap: ${(props) => props.theme.spacing.xs};
    * {
        color: ${({ theme, textColor }) =>
            textColor ?? theme.palette.text.secondary};
        font: ${({ theme }) => theme.fonts.caption};
    }
    a {
        text-decoration: underline;
    }
    p:last-child {
        margin-bottom: 0px;
    }
`;

const CaptionLink = styled(NewTabLink)`
    font: ${(props) => props.theme.fonts.caption};
    text-decoration: underline;
`;

const CaptionError = styled(FormErrorMessage)`
    margin-top: 0;
`;

const BoxInput = styled(ModalInput)`
    height: ${(props) => props.theme.spacing.md};
    width: ${(props) => props.theme.spacing.sm};
`;

const Stack = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing.xxs};
`;

export const EmailConsent = ({
    element,
    outboundLinkContext,
    companyName,
    textVariant = TextVariant.Light,
}: Props) => {
    const theme = useTheme();
    const { t } = useTranslation(CONSENTS);
    const { getFieldProps, errors } =
        useFormikContext<Record<string, string>>();

    const withCheckbox = element?.ImplicitConsent ? false : true;

    return (
        <Stack>
            <CustomText
                textColor={
                    textVariant === TextVariant.Dark
                        ? theme.palette.common.white
                        : undefined
                }
            >
                {withCheckbox && (
                    <BoxInput
                        type='checkbox'
                        required={element.IsRequired}
                        defaultChecked={getFieldProps(`['emailOptin']`).value}
                        {...getFieldProps(`['emailOptin']`)}
                    />
                )}
                {element?.CustomText ? (
                    <MdText
                        textColor={
                            textVariant === TextVariant.Dark
                                ? theme.palette.common.white
                                : theme.palette.text.primary
                        }
                        markdown={element.CustomText}
                    />
                ) : (
                    <Caption
                        color={
                            textVariant === TextVariant.Dark
                                ? theme.palette.common.white
                                : theme.palette.text.primary
                        }
                    >
                        <Trans
                            color={
                                textVariant === TextVariant.Dark
                                    ? theme.palette.common.white
                                    : theme.palette.text.primary
                            }
                            i18nKey={
                                withCheckbox
                                    ? 'explicit_email_consent'
                                    : 'implicit_email_consent'
                            }
                            t={t}
                            values={{
                                company: companyName,
                            }}
                            components={{
                                privacyPolicy: (
                                    <CaptionLink
                                        destination_category={
                                            OutboundLinkDestinationCategory.CompanyDocument
                                        }
                                        context={outboundLinkContext}
                                        shape={OutboundLinkShape.InlineText}
                                        destination_name='Privacy Policy'
                                        href={
                                            element?.PrivacyPolicyURL ||
                                            'about:blank'
                                        }
                                    >
                                        any
                                    </CaptionLink>
                                ),
                            }}
                        />
                    </Caption>
                )}
            </CustomText>
            {errors['emailOptin'] && (
                <CaptionError message={errors['emailOptin']} />
            )}
        </Stack>
    );
};
