import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';
import { useRef } from 'react';

import Icon from '../icons/icon';

type PropsType = {
    value: number;
    onChange: (selected: number) => void;
};

type StarProps = {
    selected: boolean;
};

const Array = styled.div`
    display: flex;
    justify-content: space-around;
`;

const FullStar = styled(Icon)<StyledProps<StarProps>>`
    font-size: ${(props) => props.theme.spacing.xxl};
    color: ${(props) =>
        props.selected ? 'gold' : props.theme.palette.grey[200]};
`;

const Stars = ({ value, onChange }: PropsType) => {
    const stars = [];
    const starsRef = useRef<HTMLDivElement>(null);

    for (let i = 1; i <= 5; i++) {
        stars.push(
            <FullStar
                key={i}
                name='star'
                selected={i <= value}
                onClick={() => {
                    onChange(i);
                }}
            />
        );
    }
    return <Array ref={starsRef}>{stars}</Array>;
};

export default Stars;
