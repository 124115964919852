import { AnalyticsBrowser } from '@segment/analytics-next';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

export const usePageviewEvent = (analytics: AnalyticsBrowser) => {
    const { events, asPath } = useRouter();

    const attachPageviewEvent = useCallback(() => {
        const handleRouteChange = (url: string) => analytics.page(url);
        events.on('routeChangeComplete', handleRouteChange);
        return () => events.off('routeChangeComplete', handleRouteChange);
    }, [analytics, events]);

    useEffect(() => {
        const detachPageviewEvent = attachPageviewEvent();
        analytics.ready(() => {
            console.log('[Analytics] Analytics ready');
            // Track initial page
            analytics.page(asPath);
            // Attach analytics to window
            // @ts-ignore
            window.analytics = analytics; /// TODO: Why do we wait for the analytics to be ready to attach to the window? Maybe we could get rid of the retry logic if attaching the analytics asap
        });
        return detachPageviewEvent;
    }, [analytics, asPath, events, attachPageviewEvent]);
};
