import { SerializedStyles, Theme, css } from '@emotion/react';
import { CSSProperties, ComponentProps } from 'react';

import { ButtonProps, CustomButtonProps } from './Button';

export type LinkButtonProps = CustomButtonProps & ComponentProps<'link'>;

type GetColorsProps = {
    variant: CustomButtonProps['variant'];
    color: CSSProperties['color'];
    altColor: CSSProperties['color'];
};

type GetFontSettingsProps = {
    buttonSize: CustomButtonProps['buttonSize'];
    theme: Theme;
    isUnderlined: boolean;
};

type GetSpacingProps = Omit<GetFontSettingsProps, 'isUnderlined'>;

type GetDisplayInfo = {
    variant: CustomButtonProps['variant'];
    variation: CustomButtonProps['variation'];
    theme: Theme;
    colorScheme: CustomButtonProps['colorScheme'];
};

type GetCursorProps = {
    isLoading?: boolean;
    disabled?: boolean;
};

type GetSizesProps = {
    buttonSize: CustomButtonProps['buttonSize'];
    isFullWidth: CustomButtonProps['isFullWidth'];
    maxWidth: CSSProperties['maxWidth'];
};

const getColors = ({
    variant,
    color,
    altColor,
}: GetColorsProps): CSSProperties => {
    switch (variant) {
        case 'shadow':
        case 'ghost':
        case 'underline':
        case 'outline':
            return {
                backgroundColor: 'transparent',
                color: color,
            };
        case 'solid':
            return {
                backgroundColor: color,
                color: altColor,
            };

        default:
            return {
                backgroundColor: 'white',
                color: altColor,
            };
    }
};

const getFontSettings = ({
    buttonSize,
    theme,
    isUnderlined,
}: GetFontSettingsProps): CSSProperties => {
    const { textTransform, ...rest } = theme.typography.button;
    const textDecoration: CSSProperties = isUnderlined
        ? { textDecoration: 'underline' }
        : {};

    if (buttonSize === 'small') {
        return {
            ...theme.typography.caption,
            ...textDecoration,
            textTransform:
                (textTransform as CSSProperties['textTransform']) ?? 'none',
            ...rest,
            fontSize: '1rem',
            lineHeight: '1rem',
        };
    } else {
        return {
            ...rest,
            ...textDecoration,
            textTransform:
                (textTransform as CSSProperties['textTransform']) ?? 'none',
            ...rest,
        };
    }
};

const getSpacing = ({ buttonSize, theme }: GetSpacingProps): CSSProperties => {
    switch (buttonSize) {
        case 'small':
            return {
                paddingInline: theme.spacing.sm,
                paddingBlock: theme.spacing.xs,
            };
        case 'medium':
            return {
                paddingInline: theme.spacing.md,
            };
        default:
            return {
                paddingInline: theme.spacing.lg,
            };
    }
};

const getCursor = ({ isLoading, disabled }: GetCursorProps): CSSProperties => {
    if (isLoading) {
        return { cursor: 'wait' };
    }

    if (disabled) {
        return { cursor: 'not-allowed' };
    }

    return { cursor: 'pointer' };
};

const getDisplayInfo = ({
    variant,
    variation,
    theme,
    colorScheme,
}: GetDisplayInfo) => {
    const isPrimaryVariation = variation === 'primary';
    const isSecondaryVariation = variation === 'secondary';
    const isUndefinedVariation = variation === undefined;
    const isOutlined = variant === 'outline';
    const isUnderlined = variant === 'underline';
    const isText = variant === 'ghost';
    const isBoxShadow =
        variant === 'shadow' ||
        (isPrimaryVariation && !isUnderlined && !isText);
    return {
        isPrimaryVariation,
        isSecondaryVariation,
        isUndefinedVariation,
        isOutlined,
        isUnderlined,
        isText,
        isBoxShadow,
        color:
            colorScheme === 'black'
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
        altColor:
            colorScheme === 'black'
                ? theme.palette.primary.contrastText
                : theme.palette.secondary.contrastText,
    };
};

const getSizes = ({ buttonSize, isFullWidth, maxWidth }: GetSizesProps) => {
    return {
        minWidth: buttonSize == 'medium' ? '5rem' : 'fit-content',
        width: isFullWidth ? '100%' : 'fit-content',
        maxWidth,
    };
};

const getBorders = (isOutlined: boolean): CSSProperties => {
    if (isOutlined) {
        return {
            border: '1px solid black',
        };
    } else {
        return {
            border: 'none',
        };
    }
};

export const getButtonStyle = (
    props: CustomButtonProps & { theme: Theme }
): SerializedStyles => {
    const {
        buttonSize = 'medium',
        colorScheme = 'black',
        theme,
        variant = 'solid',
        variation,
        isLoading,
        disabled,
        isFullWidth,
        maxWidth,
    } = props;

    const { isOutlined, isUnderlined, isBoxShadow, color, altColor } =
        getDisplayInfo({
            variant,
            variation,
            theme,
            colorScheme,
        });

    return css({
        gap: '1rem',
        alignItems: 'center',
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'inline-flex',
        justifyContent: 'center',
        pointerEvents: 'auto',
        position: 'relative',
        borderRadius: theme.shape.radius.default,
        boxShadow: isBoxShadow ? theme.shadows.default : 'none',
        opacity: isLoading ? 0.8 : 1,
        lineHeight: theme.typography.button.lineHeight,
        ...getBorders(isOutlined),
        ...getColors({ variant, color, altColor }),
        ...getCursor({ isLoading, disabled }),
        ...getSizes({ buttonSize, isFullWidth, maxWidth }),
        ...getSpacing({ buttonSize, theme }),
        ...getFontSettings({ buttonSize, theme, isUnderlined }),
        ':first-letter': { textTransform: 'capitalize' },
        ':disabled': {
            backgroundColor: theme.palette.actions.disabled,
        },
        fontWeight: props.weight,
    });
};

export const getRippleColor = ({
    variant,
    colorScheme,
}: {
    variant: ButtonProps['variant'];
    colorScheme?: ButtonProps['colorScheme'];
}) => {
    switch (variant) {
        case 'underline':
        case 'ghost':
        case 'outline':
        case 'shadow':
            return 'rgba(0, 0, 0, 0.3)';

        case 'solid':
        default:
            if (!colorScheme || colorScheme === 'black') {
                return 'rgba(255, 255, 255, 0.3)';
            } else {
                return 'rgba(0, 0, 0, 0.15)';
            }
    }
};
