import CheckBoxInput from '@components/utils/inputElements/checkBoxInput';
import { PhoneInput } from '@components/utils/inputElements/phoneInput';
import RatingInput from '@components/utils/inputElements/ratingInput';
import {
    Enum_Componentformfieldsinput_Type,
    InputFieldFragment,
} from '@graphql/generated/graphql';
import { FormControl, Input } from '@lib/uikit';
import { useFormikContext } from 'formik';

type PropsType = {
    element: InputFieldFragment;
};

export const RenderCustomInput = ({ element }: PropsType) => {
    const { errors, getFieldProps } =
        useFormikContext<Record<string, unknown>>();

    switch (element.Type) {
        case Enum_Componentformfieldsinput_Type.Text:
        case Enum_Componentformfieldsinput_Type.Number:
        case Enum_Componentformfieldsinput_Type.Password:
        case Enum_Componentformfieldsinput_Type.Email: {
            return (
                <FormControl
                    isRequired={element.IsRequired}
                    name={element.Name}
                    label={element.Label}
                    errorMessage={errors[element.Name] as string | undefined}
                    key={element.Name}
                >
                    <Input
                        type={element.Type}
                        placeholder={
                            element.Placeholder &&
                            element.Placeholder +
                                (element.IsRequired && !element.Label
                                    ? '*'
                                    : '')
                        }
                        {...getFieldProps(`['${element.Name}']`)}
                    />
                </FormControl>
            );
        }

        case Enum_Componentformfieldsinput_Type.Tel: {
            return (
                <PhoneInput
                    name={element.Name}
                    label={element.Label}
                    placeholder={element.Placeholder}
                    isRequired={element.IsRequired}
                    errorMessage={errors[element.Name]}
                />
            );
        }

        case Enum_Componentformfieldsinput_Type.Box: {
            return <CheckBoxInput element={element} key={element.Name} />;
        }

        case Enum_Componentformfieldsinput_Type.Rating: {
            return <RatingInput element={element} key={element.Name} />;
        }

        default:
            throw new Error(`Invalid input type : ${element.Type}`);
    }
};
