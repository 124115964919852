export const AI = 'ai';
export const ARTICLE = 'article';
export const COMMON = 'common';
export const COOKIES = 'cookies';
export const FORM = 'form';
export const HOME = 'home';
export const NEWSLETTER = 'newsletter';
export const ONBOARDING = 'onboarding';
export const RATINGS = 'ratings';
export const SEARCH = 'search';
export const STORY = 'story';
export const CONSENTS = 'consents';
