import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Card, CardSubtitle, CardTitle } from '@lib/uikit';
import { isString } from 'formik';
import { useState } from 'react';

import { Choice, SelectProps } from './types';
import { Pastille } from '../icons/pastille';
import Ripple from '../ripple';


type RadioCardSelectField = SelectProps & {
    multipleChoice: boolean;
    maxChoices?: number;
    onChange?: (value: string[]) => void;
    selectedChoices?: string[];
};

const Flexbox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing.md};
`;

const StyledRadioCard = styled(Card)<{ isSelected: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacing.md};
    ${(props) =>
        props.isSelected &&
        `border: 2px solid ${props.theme.palette.primary.main}`};
`;

const VStack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${(props) => props.theme.spacing.xxs};
`;

const StyledPastille = styled(Pastille)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: ${(props) => props.theme.palette.secondary.light};
    width: 30px;
    height: 30px;
    border-radius: ${(props) => props.theme.shape.radius.sm};
    color: white;
`;

const Image = styled.img`
    width: 30px;
    height: 30px;
    border-radius: ${(props) => props.theme.shape.radius.sm};
    object-fit: cover;
`;

const RadioCardSelectField = (props: RadioCardSelectField) => {
    const theme = useTheme();
    const { choices, multipleChoice, maxChoices } = props;

    const [selectedChoices, setSelectedChoices] = useState<string[]>(
        props.selectedChoices ?? []
    );

    const handleChoiceClick = (choice: Choice) => {
        const selectedChoiceValue = isString(choice) ? choice : choice.value;

        if (!multipleChoice) {
            setSelectedChoices([selectedChoiceValue]);
            props.onChange?.([selectedChoiceValue]);
        } else {
            if (selectedChoices.includes(selectedChoiceValue)) {
                const newChoices = selectedChoices.filter(
                    (c) => c !== selectedChoiceValue
                );
                setSelectedChoices(newChoices);
                props.onChange?.(newChoices);
            } else if (
                selectedChoices.length < (maxChoices ?? choices.length)
            ) {
                const newChoices = [...selectedChoices, selectedChoiceValue];
                setSelectedChoices(newChoices);
                props.onChange?.(newChoices);
            }
        }
    };

    const isChoiceSelected = (choice: Choice) => {
        const selectedChoiceValue = isString(choice) ? choice : choice.value;
        return selectedChoices.includes(selectedChoiceValue);
    };

    return (
        <Flexbox>
            {choices?.map((choice, index) => (
                <StyledRadioCard
                    key={'choice-' + index}
                    variant='outline'
                    onClick={() => handleChoiceClick(choice)}
                    isSelected={isChoiceSelected(choice)}
                >
                    {!isString(choice) && choice.imageUrl ? (
                        <Image src={choice.imageUrl} alt='Choice Image' />
                    ) : (
                        <StyledPastille>
                            {String.fromCharCode(index + 'A'.charCodeAt(0))}
                        </StyledPastille>
                    )}
                    <VStack>
                        {isString(choice) ? (
                            <CardTitle>{choice}</CardTitle>
                        ) : (
                            <>
                                <CardTitle style={{ fontWeight: 'bold' }}>
                                    {choice.title}
                                </CardTitle>

                                {choice.subtitle && (
                                    <CardSubtitle>
                                        {choice.subtitle}
                                    </CardSubtitle>
                                )}
                            </>
                        )}
                    </VStack>
                    <Ripple color={theme.components.ripple.color} />
                </StyledRadioCard>
            ))}
        </Flexbox>
    );
};

export default RadioCardSelectField;
