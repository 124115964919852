import Icon from '@components/utils/icons/icon';
import { ARTICLE } from '@constants';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';

type PropsType = { children: React.ReactNode; callout: string };

const Container = styled.div`
    background-color: ${(props) => props.theme.palette.background.paper};
    border-radius: ${(props) => props.theme.spacing.xs};
    padding: ${(props) => props.theme.spacing.sm};
    margin-block: ${(props) => props.theme.spacing.md};
`;

const HStack = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline-start: ${(props) => props.theme.spacing.sm};
`;

const Title = styled.p`
    font-weight: bold;
`;

const Content = styled.p``;

const Icons: Map<string, JSX.Element> = new Map([
    ['info', <Icon key='info' size='medium' name='info' color='blue' />],
    [
        'warning',
        <Icon key='warning' size='medium' name='warning' color='#fbb01b' />,
    ],
    ['danger', <Icon key='danger' size='medium' name='error' color='red' />],
]);

const CalloutMarkdown: React.FC<PropsType> = ({ callout, children }) => {
    const { t } = useTranslation(ARTICLE);
    const type = useMemo(() => {
        if (callout.includes('warning')) {
            return 'warning';
        }

        if (callout.includes('danger')) {
            return 'danger';
        }

        return 'info';
    }, [callout]);

    return (
        <Container>
            <HStack>
                {Icons.get(type)}
                <TextContainer>
                    <Title>{t(`callout.title.${type}`)}</Title>
                    <Content>{children}</Content>
                </TextContainer>
            </HStack>
        </Container>
    );
};

export default CalloutMarkdown;
