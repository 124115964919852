type EventListener = () => void;

export class Queue<T> {
    private storage: T[] = [];
    private listeners: Map<number, EventListener> = new Map();

    constructor() {
        this.storage = [];
    }

    // Add an item to the end of the queue
    enqueue(item: T): void {
        this.storage.push(item);
        this.notify();
    }

    // Remove and return the item at the front of the queue
    dequeue(): T | undefined {
        if (this.isEmpty()) {
            return undefined;
        }
        return this.storage.shift();
    }

    // Get the item at the front of the queue without removing it
    peek(): T | undefined {
        return this.storage[0];
    }

    // Check if the queue is empty
    isEmpty(): boolean {
        return this.storage.length === 0;
    }

    // Get the size of the queue
    size(): number {
        return this.storage.length;
    }

    private notify() {
        this.listeners.forEach((listener) => listener());
    }

    addEventListener(listener: EventListener): {
        removeEventListener: () => void;
    } {
        const listenerId = new Date().getTime();
        this.listeners.set(listenerId, listener);

        return {
            removeEventListener: () => {
                this.listeners.delete(listenerId);
            },
        };
    }
}
