import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useMemo } from 'react';

import createApolloClient from './apolloClient';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export function initializeApollo(
    isPreview: boolean = false,
    initialState: NormalizedCacheObject | null = null
) {
    const _apolloClient = apolloClient ?? createApolloClient(isPreview);

    // If your page has Next.js data fetching methods that use Apollo Client,
    // the initial state gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Restore the cache using the data passed from
        // getStaticProps/getServerSideProps combined with the existing cached data
        _apolloClient.cache.restore({
            ...existingCache,
            ...initialState,
        } as NormalizedCacheObject);
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;

    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;
    return _apolloClient;
}

export function useApollo(
    isPreview: boolean,
    initialState: NormalizedCacheObject | null
) {
    const store = useMemo(
        () => initializeApollo(isPreview, initialState),
        [initialState, isPreview]
    );
    return store;
}
