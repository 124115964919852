import {
    ModuleContentFragment,
    ModuleFragment,
} from '@graphql/generated/graphql';

export enum ModuleAction {
    AI_CHAT = 'aiChat',
    EVENT = 'event',
    EXTERNAL_LINK = 'externalLink',
    FORM = 'form',
    INTERNAL_LINK = 'internalLink',
    NAVIGATION = 'navigation',
    STRUCTURE = 'structure',
    UNKNOWN = 'unknown',
}

export type OrderedModule = ModuleFragment & {
    Action: ModuleAction;
    Module: NonNullable<ModuleFragment['Module']>;
};

export type Module = NonNullable<OrderedModule['Module']>;

export type ModuleContent = ModuleContentFragment['Content'];

export type LockableModuleProps = {
    orderedModule: OrderedModule;
    isLocked?: boolean;
    onClick?: () => void;
};
