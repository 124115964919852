import { Content } from '@components/navigation/navigationTray';
import {
    LEAF_ROUTES,
    ROUTE_ARTICLE,
    ROUTE_PRODUCT,
    ROUTE_SEARCH,
    ROUTE_STORY,
} from '@constants';
import {
    ModuleContentFragment,
    ModuleDisplayType,
    ModuleFragment,
    ContentArticleFragment,
    ContentStoryFragment,
} from '@graphql/generated/graphql';
import { OrderedModule, ModuleAction } from '@types';
import { useRouter } from 'next/router';
import {
    createContext,
    Dispatch,
    MutableRefObject,
    PropsWithChildren,
    RefObject,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

interface SaveNavigationInfoPayload {
    module: OrderedModule;
    scrollLeft?: number;
}

interface ProductNavigationContext {
    containerRef: MutableRefObject<HTMLDivElement | null>;
    scrollTop?: number;
    scrollLeft?: number;
    selectedModule?: OrderedModule;
    isTrayOpen: boolean;
    moduleNavigationContent?: (Content | undefined)[];
    setScrollTop: Dispatch<SetStateAction<number | undefined>>;
    setScrollLeft: Dispatch<SetStateAction<number | undefined>>;
    setSelectedModule: Dispatch<SetStateAction<OrderedModule | undefined>>;
    closeTray: () => void;
    saveNavigationInfo: ({
        module,
        scrollLeft,
    }: SaveNavigationInfoPayload) => void;
    restoreNavigation: () => void;
    maybeRestoreHorizontalNavigation: (
        scrollerRef: RefObject<HTMLUListElement>,
        module: OrderedModule | ModuleFragment
    ) => void;
}

export const ProductNavigationContext = createContext<ProductNavigationContext>(
    {} as ProductNavigationContext
);

export const ProductNavigationProvider = ({ children }: PropsWithChildren) => {
    const router = useRouter();
    const { query, pathname } = router;
    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollTop, setScrollTop] = useState(containerRef.current?.scrollTop);
    const [scrollLeft, setScrollLeft] = useState<number>();
    const [selectedModule, setSelectedModule] = useState<OrderedModule>();
    const [isTrayOpen, setIsTrayOpen] = useState(false);

    const routesToRestoreNavigation = useMemo(
        () => [...LEAF_ROUTES, ROUTE_SEARCH],
        []
    );

    const resetModuleNavigationInfo = useCallback(() => {
        setScrollLeft(0);
        setSelectedModule(undefined);
        setIsTrayOpen(false);
    }, []);

    const resetNavigationInfo = useCallback(() => {
        setScrollTop(0);
        resetModuleNavigationInfo();
    }, [resetModuleNavigationInfo]);

    useEffect(() => {
        const handleRouteChange = () => {
            if (pathname === ROUTE_PRODUCT) {
                return setScrollTop(containerRef.current?.scrollTop);
            }

            if (pathname !== ROUTE_STORY) {
                setScrollLeft(0);
            }

            if (
                routesToRestoreNavigation.includes(pathname) &&
                !LEAF_ROUTES.includes(pathname)
            ) {
                return resetModuleNavigationInfo();
            }

            if (!routesToRestoreNavigation.includes(pathname)) {
                resetNavigationInfo();
            }
        };

        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [
        pathname,
        routesToRestoreNavigation,
        router,
        resetModuleNavigationInfo,
        resetNavigationInfo,
    ]);

    const maybeOpenTray = useCallback((module: OrderedModule) => {
        if (
            module.Action === ModuleAction.NAVIGATION &&
            module.Module.DisplayType !== ModuleDisplayType.RoundGallery &&
            module.Module.DisplayType !== ModuleDisplayType.SquareGallery &&
            module.Module.Content &&
            module.Module.Content.length > 1
        ) {
            setIsTrayOpen(true);
        }
    }, []);

    const saveNavigationInfo = useCallback(
        ({ module, scrollLeft }: SaveNavigationInfoPayload) => {
            setScrollTop(containerRef.current?.scrollTop);
            setSelectedModule(module);
            maybeOpenTray(module);
            setScrollLeft(scrollLeft);
        },
        [maybeOpenTray]
    );

    const restoreNavigation = useCallback(() => {
        containerRef.current?.scrollTo({
            top: scrollTop || 0,
            behavior: 'instant',
        });
        if (selectedModule) maybeOpenTray(selectedModule);
    }, [selectedModule, scrollTop, maybeOpenTray]);

    const maybeRestoreHorizontalNavigation = useCallback(
        (
            scrollerRef: RefObject<HTMLUListElement>,
            module: OrderedModule | ModuleFragment
        ) => {
            if (
                scrollLeft &&
                scrollerRef.current &&
                module.Module?.id === selectedModule?.Module?.id
            ) {
                scrollerRef.current.scrollLeft = scrollLeft;
            }
        },
        [scrollLeft, selectedModule?.Module.id]
    );

    const closeTray = useCallback(() => {
        setIsTrayOpen(false);
    }, []);

    const moduleNavigationContent = useMemo(():
        | Array<Content | undefined>
        | undefined => {
        return selectedModule?.Module?.Content?.map(
            (content: Unpacked<ModuleContentFragment['Content']>, index) => {
                if (!content) return;

                switch (content.__typename) {
                    case 'ComponentContentArticle':
                        content = content as ContentArticleFragment;

                        if (!content.Article) return;

                        return {
                            id: content.Article.id,
                            Title: content.Article.Title,
                            href: {
                                pathname: ROUTE_ARTICLE,
                                query: {
                                    ...query,
                                    slug: content.Article.Slug!,
                                },
                            },
                            as: `/${query.product}/article/${content.Article.Slug}`,
                            isDropdown: false,
                        };

                    case 'ComponentContentStory':
                        content = content as ContentStoryFragment;

                        if (!content.Story) return;

                        return {
                            id: content.Story.id,
                            Title: content.Story.Title,
                            href: {
                                pathname: '/[company]/[product]/story/[slug]',
                                query: {
                                    ...query,
                                    slug: content.Story.Slug!,
                                    module: selectedModule.Module!.id,
                                    story_order: (index + 1).toString(),
                                },
                            },
                            as: `/${query.product}/story/${content.Story?.Slug}`,
                            isDropdown: false,
                        };

                    default:
                        return undefined;
                }
            }
        );
    }, [query, selectedModule]);

    return (
        <ProductNavigationContext.Provider
            value={{
                containerRef,
                scrollTop,
                scrollLeft,
                selectedModule,
                isTrayOpen,
                moduleNavigationContent,
                setScrollTop,
                setScrollLeft,
                setSelectedModule,
                closeTray,
                saveNavigationInfo,
                restoreNavigation,
                maybeRestoreHorizontalNavigation,
            }}
        >
            {children}
        </ProductNavigationContext.Provider>
    );
};

export const useProductNavigation = () => useContext(ProductNavigationContext);
