import {
    ApolloClient,
    ApolloLink,
    from,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { PublicationStatus } from '@enums';

// Handle Apollo errors
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: [${locations
                    ?.map((l) => `${l.line}:${l.column}`)
                    .join(', ')} ], Path: ${path}, Operation: ${
                    operation.operationName
                }`
            )
        );
    if (networkError) console.log(`[GraphQL Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_STRAPI_GRAPHQL,
    includeExtensions: true,
});

const previewLink = new ApolloLink((operation, forward) => {
    operation.extensions = { publicationStatus: PublicationStatus.Draft };

    return forward(operation);
});

const dataLink = new ApolloLink((operation, forward) => {
    operation.extensions = { publicationStatus: PublicationStatus.Live };

    return forward(operation);
});

function createApolloClient(isPreview: boolean) {
    return new ApolloClient({
        ssrMode: typeof window === 'undefined',
        cache: new InMemoryCache(),
        link: isPreview
            ? from([errorLink, previewLink, httpLink])
            : from([errorLink, dataLink, httpLink]),
    });
}

export default createApolloClient;
