import { getStrapiAssetUrl } from '@lib/strapi';

import stringEndsWith from './stringEndsWith';
import { Format, Formats } from './types';

const getFormat = (
    format: Format | Format[],
    webp: boolean
): Format | undefined => {
    if (Array.isArray(format)) {
        return webp
            ? format.find((f) => stringEndsWith(f.url, '.webp'))
            : format.find((f) => !stringEndsWith(f.url, '.webp'));
    }
    if (webp) {
        return stringEndsWith(format.url, '.webp') ? format : undefined;
    } else {
        return !stringEndsWith(format.url, '.webp') ? format : undefined;
    }
};

function getSrcSet(formats: Formats, webp?: boolean): string | null {
    if (!formats) return null;
    const formatsNormalized = Object.keys(formats)
        .map((key) => {
            const format = getFormat(formats[key], webp as boolean);
            return format
                ? { width: format.width, url: getStrapiAssetUrl(format) }
                : null;
        })
        .filter((format) => format);

    return !formatsNormalized.length
        ? null
        : formatsNormalized
              .sort((a, b) => {
                  if ((a?.width || 0) < (b?.width || 0)) return -1;
                  if ((a?.width || 0) > (b?.width || 0)) return 1;
                  return 0;
              })
              .reduce((acc, current, index: number, array) => {
                  return (
                      acc +
                      `${current?.url} ${current?.width}w${
                          index < array.length - 1 ? ', ' : ''
                      }`
                  );
              }, '');
}

export default function getSrcSets(formats: Formats): {
    regular: string | null;
    webp: string | null;
} {
    return {
        regular: getSrcSet(formats),
        webp: getSrcSet(formats, true),
    };
}
