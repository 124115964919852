import {
    SinglePageFormComponentFragment,
    MultiPagesFormComponentFragment,
    SinglePageFormElementsDynamicZoneFragment,
    MultiPagesFormElementsDynamicZoneFragment,
    InputFieldFragment,
    DateInputFieldFragment,
    SingleSelectFieldFragment,
    NewsletterFormFragment,
    RegistrationFormFragment,
    ReviewFormFragment,
    EmailConsentFragment,
    RichTextFragment,
    FormPageComponentFragment,
    FormPageElementsDynamicZoneFragment,
    FileInputFieldFragment,
} from '@graphql/generated/graphql';

export type FormFieldOrConsent = FormFieldComponent | EmailConsentFragment;

export type FormElementComponent = FormFieldOrConsent | RichTextFragment;

export type FormFieldComponent =
    | DateInputFieldFragment
    | InputFieldFragment
    | FileInputFieldFragment
    | SingleSelectFieldFragment;

export type FormTemplateComponent =
    | NewsletterFormFragment
    | RegistrationFormFragment
    | ReviewFormFragment;

export type FormPageComponent = FormPageComponentFragment;

export type SinglePageCustomFormComponent = {
    Form: {
        Elements: (FormFieldOrConsent | RichTextFragment)[];
    } & SinglePageFormComponentFragment['Form'];
} & SinglePageFormComponentFragment;

export type SinglePageCustomForm = SinglePageCustomFormComponent['Form'];

export type SinglePageTemplateFormComponent = {
    Form: {
        Elements: FormTemplateComponent[];
    } & SinglePageFormComponentFragment['Form'];
} & SinglePageFormComponentFragment;

export type SinglePageTemplateForm = SinglePageTemplateFormComponent['Form'];

export type MultiPagesFormComponent = {
    Form: {
        Elements: FormPageComponent[];
    } & MultiPagesFormComponentFragment['Form'];
} & MultiPagesFormComponentFragment;

export type MultiPagesForm = MultiPagesFormComponent['Form'];

export type SinglePageFormElementsDynamicZone =
    SinglePageFormElementsDynamicZoneFragment['Elements'];

export type SinglePageFormElementsDynamicZoneComponent =
    SinglePageFormElementsDynamicZone[0];

export type MultiPagesFormElementsDynamicZone =
    MultiPagesFormElementsDynamicZoneFragment['Elements'];

export type MultiPagesFormElementsDynamicZoneComponent =
    MultiPagesFormElementsDynamicZone[0];

export type FormPageElementsDynamicZone =
    FormPageElementsDynamicZoneFragment['Elements'];

export type FormPageElementsDynamicZoneComponent =
    FormPageElementsDynamicZone[0];

export type DynamicFormSchemaType = Record<string, unknown>;

export enum FormStatus {
    READY = 'READY',
    DISABLED = 'DISABLED',
    UPLOADING = 'UPLOADING',
}
