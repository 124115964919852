import {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from 'react';

interface Helpers {
    goToNextStep: () => void;
    goToPrevStep: () => void;
    resetStep: () => void;
    canGoToNextStep: boolean;
    canGoToPrevStep: boolean;
    isFirstStep: boolean;
    isLastStep: boolean;
    setStep: Dispatch<SetStateAction<number>>;
}

type setStepCallbackType = (step: number | ((step: number) => number)) => void;

export function useStep(maxStep: number): [number, Helpers] {
    const [currentStep, setCurrentStep] = useState<number>(1);

    const canGoToNextStep = useMemo(
        () => currentStep + 1 <= maxStep,
        [currentStep, maxStep]
    );

    const canGoToPrevStep = useMemo(() => currentStep - 1 >= 1, [currentStep]);

    const isFirstStep = useMemo(() => currentStep === 1, [currentStep]);
    const isLastStep = useMemo(
        () => currentStep === maxStep,
        [currentStep, maxStep]
    );

    const setStep = useCallback<setStepCallbackType>(
        (step) => {
            // Allow value to be a function so we have the same API as useState
            const newStep = step instanceof Function ? step(currentStep) : step;

            if (newStep >= 1 && newStep <= maxStep) {
                setCurrentStep(newStep);
                return;
            }

            throw new Error('Step not valid');
        },
        [maxStep, currentStep]
    );

    const goToNextStep = useCallback(() => {
        if (canGoToNextStep) {
            setCurrentStep((step) => step + 1);
        }
    }, [canGoToNextStep]);

    const goToPrevStep = useCallback(() => {
        if (canGoToPrevStep) {
            setCurrentStep((step) => step - 1);
        }
    }, [canGoToPrevStep]);

    const resetStep = useCallback(() => {
        setCurrentStep(1);
    }, []);

    return [
        currentStep,
        {
            goToNextStep,
            goToPrevStep,
            canGoToNextStep,
            canGoToPrevStep,
            isFirstStep,
            isLastStep,
            setStep,
            resetStep,
        },
    ];
}
