import Icon from '@components/utils/icons/icon';
import { StyledProps, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/react';
import { VStack } from '@lib/uikit';
import { dynamicWidth } from '@lib/uikit/theme/defaultTheme';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useRouter } from 'next/router';
import {
    MouseEventHandler,
    PropsWithChildren,
    ReactNode,
    TouchEventHandler,
    useCallback,
    useEffect,
    useState,
} from 'react';

export type TrayProps = {
    title?: string;
    description?: string;
    backgroundBlur?: number;
    allowClose?: boolean;
    isOpen: boolean;
    onClose?: () => void;
};

interface DialogPanelProps {
    translation: number;
    dragging: number;
    children: ReactNode;
}

interface BackdropProps {
    translation: number;
    blurbackground: number;
}

const Panel = styled(DialogPanel)<StyledProps<DialogPanelProps>>`
    position: relative;
    max-height: 80%;
    width: 100%;
    transition:
        transform ${(props) => (props.dragging ? 0.5 : 0)}s,
        opacity 0.1s;
    transform: translateY(${(props) => props.translation}%);
    opacity: ${(props) =>
        props.translation
            ? props.translation < 30
                ? 100
                : 100 - props.translation
            : 100}%;
`;

const Card = styled(motion.div)`
    height: 100%;
    background-color: ${(props) => props.theme.palette.background.paper};
    box-shadow: ${(props) => props.theme.shadows.default};
    padding-inline: ${(props) => props.theme.spacing.md};
    padding-block: ${(props) => props.theme.spacing.xs};
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    @media (min-width: 748px) {
        border-bottom-left-radius: ${(props) =>
            props.theme.shape.radius.default};
        border-bottom-right-radius: ${(props) =>
            props.theme.shape.radius.default};
    }

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    z-index: 9999;
`;

const Backdrop = styled(motion.div)<StyledProps<BackdropProps>>`
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    @media (min-width: 748px) {
        border-radius: ${(props) => props.theme.shape.radius.default};
    }
    -webkit-backdrop-filter: blur(
            ${(props) =>
                props.blurbackground &&
                props.translation &&
                props.translation < 50
                    ? 3
                    : 0}px
        )
        brightness(60%);
    backdrop-filter: blur(
            ${(props) =>
                props.blurbackground &&
                props.translation &&
                props.translation < 50
                    ? 4
                    : 0}px
        )
        brightness(60%);
    transition: backdrop-filter 0.3s;
    z-index: 9999;
    @media (min-width: 748px) {
        height: calc(100% - 2rem);
        margin-block-start: 1rem;
    }
    ${dynamicWidth}

    // Prevent tray from going longer than the body
    max-height: 1330px;
    margin-block: auto !important;
`;

const DragIconBox = styled.div`
    width: 100%;
    position: absolute;
    top: ${(props) => props.theme.spacing.xxs};
    left: 0;
    display: flex;
    justify-content: center;
`;

const ScrollContainer = styled.div`
    overflow: scroll;
    width: 100%;
    max-width: 100;
    max-height: 100%;
`;

const backdropMotion: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { ease: 'easeIn', duration: 0.2 } },
    exit: { opacity: 0, transition: { ease: 'easeOut', duration: 0.2 } },
};

const cardMotion: Variants = {
    initial: { y: '100%' },
    animate: { y: 0 },
    exit: { y: '100%' },
};

const Tray = ({
    title,
    description,
    backgroundBlur = 1,
    allowClose = true,
    children,
    isOpen,
    onClose,
}: PropsWithChildren<TrayProps>) => {
    const theme = useTheme();
    const [position, setPosition] = useState<number>(0);
    const [translation, setTranslation] = useState<number>(0);
    const [dragging, setDragging] = useState<number>(0);
    const router = useRouter();

    const handleCloseDialog = useCallback(() => {
        if (!allowClose) return;
        onClose?.();
    }, [allowClose, onClose]);

    useEffect(() => {
        if (translation === 0) {
            setPosition(0);
        }

        if (translation === 100) {
            setTimeout(() => handleCloseDialog(), 400);
        }
    }, [translation, handleCloseDialog]);

    useEffect(() => {
        setDragging(0);
        setTranslation(0);
    }, [isOpen]);

    useEffect(() => {
        const handleRouteChange = () => onClose?.();
        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events, onClose]);

    // Touch section
    const handleTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
        if (!allowClose) return;
        const touchDown = e.touches[0].clientY;
        setPosition(touchDown);
        setDragging(1);
    };

    const handleTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
        const touchDown = position;
        if (touchDown === null) return;
        const currentTouch = e.touches[0].clientY;
        const diff = (touchDown - currentTouch) * -1;
        const vh = document.body.getBoundingClientRect().height;
        const full = vh - (position ?? 0);

        if (diff > 0) {
            const translate = Math.min((100 * diff) / full, 100);
            setTranslation(translate);
        }
    };

    const handleTouchEnd: TouchEventHandler<HTMLDivElement> = () => {
        setDragging(0);

        if (translation > 50) {
            setTranslation(100);
        } else {
            setTranslation(0);
        }
    };

    // Mouse section
    const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
        if (!allowClose) return;
        e.stopPropagation();
        const touchDown = e.clientY;
        setPosition(touchDown);
    };

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
        const touchDown = position;
        if (!touchDown) return;
        const currentTouch = e.clientY;
        const diff = (touchDown - currentTouch) * -1;
        const vh = document.body.getBoundingClientRect().height;
        const full = vh - (position ?? 0);

        if (diff > 0) {
            const translate = Math.min((100 * diff) / full, 100);
            setTranslation(translate);
        }
    };

    const handleMouseUp: MouseEventHandler<HTMLDivElement> = () => {
        if (translation > 40) {
            setTranslation(100);
        } else {
            setTranslation(0);
        }
    };

    return (
        <AnimatePresence>
            {isOpen ? (
                <Dialog static open={isOpen} onClose={handleCloseDialog}>
                    <Backdrop
                        translation={translation}
                        blurbackground={backgroundBlur}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        variants={backdropMotion}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                    >
                        <Panel translation={translation} dragging={dragging}>
                            <Card
                                variants={cardMotion}
                                initial='initial'
                                animate='animate'
                                exit='exit'
                                transition={{ duration: 0.25 }}
                            >
                                <DragIconBox
                                    onTouchStart={handleTouchStart}
                                    onTouchMove={handleTouchMove}
                                    onTouchEnd={handleTouchEnd}
                                    onMouseDown={handleMouseDown}
                                >
                                    <Icon
                                        name='maximize'
                                        color={theme.palette.text.disabled}
                                        size='medium'
                                    />
                                </DragIconBox>
                                <VStack
                                    spacing='sm'
                                    maxHeight='100%'
                                    paddingBlock='sm'
                                >
                                    {title && (
                                        <DialogTitle>{title}</DialogTitle>
                                    )}
                                    {description && (
                                        <Description>{description}</Description>
                                    )}
                                    <ScrollContainer>
                                        {children}
                                    </ScrollContainer>
                                </VStack>
                            </Card>
                        </Panel>
                    </Backdrop>
                </Dialog>
            ) : null}
        </AnimatePresence>
    );
};

export default Tray;
