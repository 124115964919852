import { DefaultApi } from '@hello-pimster/pimster-crm-sdk/dist/api';
import { Configuration } from '@hello-pimster/pimster-crm-sdk/dist/configuration';
import axios, * as Axios from 'axios';
import qs from 'qs';

type ResponseInterceptorFulfilled = (
    value: Axios.AxiosResponse
) => Axios.AxiosResponse | Promise<Axios.AxiosResponse>;

type ResponseInterceptorRejected = (error: unknown) => unknown;

type ResponseInterceptorCondition = (value: Axios.AxiosResponse) => boolean;

export type ResponseInterceptor = {
    /**
     * A function that will be executed if the request is successful.
     */
    fulfilled: ResponseInterceptorFulfilled;

    /**
     * (Optional) A function that will be executed if the request encounters an error.
     */
    rejected?: ResponseInterceptorRejected;

    /**
     * (Optional) A condition indicating whether the interceptor should be executed.
     * If not provided, it defaults to `true`, meaning `fulfilled` will always be executed.
     */
    condition?: ResponseInterceptorCondition;
};

export const createApiCrmClient = (
    headers: Partial<Axios.AxiosRequestHeaders>,
    responseInterceptors: ResponseInterceptor[] = []
): DefaultApi => {
    const axiosConfig: Axios.AxiosRequestConfig = {
        paramsSerializer: function (params: object) {
            return qs.stringify(params);
        },
        headers: new Axios.AxiosHeaders(headers),
    };
    const config = new Configuration({
        basePath: process.env.NEXT_PUBLIC_API_CRM_URL,
        baseOptions: axiosConfig,
    });
    const axiosInstance = axios.create(axiosConfig);

    const defaultErrorInterceptor: ResponseInterceptor = {
        fulfilled: (res) => res,
        rejected: (err) => console.error('[CRM SDK Error]', err),
    };

    responseInterceptors.unshift(defaultErrorInterceptor);

    responseInterceptors.forEach((responseInterceptor) => {
        axiosInstance.interceptors.response.use(
            (res) => {
                if (
                    !responseInterceptor.condition ||
                    responseInterceptor.condition(res)
                ) {
                    return responseInterceptor.fulfilled(res);
                }

                return res;
            },
            (error) => {
                responseInterceptor.rejected?.(error);
                throw error;
            },
            { synchronous: true }
        );
    });

    return new DefaultApi(config, config.basePath, axiosInstance);
};
