import { StyledProps, keyframes, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';

type CustomProps = {
    color?: string;
};

export type SpinnerProps = CustomProps & ComponentProps<'svg'>;

const Animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spin = styled.path<StyledProps<CustomProps>>`
    transform-origin: center;
    fill: ${({ color }) => color};
    animation: ${Animation} 0.75s infinite linear;
`;

export const Spinner: FC<SpinnerProps> = ({ color }) => {
    const { palette } = useTheme();

    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
        >
            <Spin
                color={color || palette.common.black}
                d='M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z'
            />
        </svg>
    );
};
