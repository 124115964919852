/*
File from https://github.com/segmentio/consent-manager/blob/master/src/consent-manager-builder/preferences.ts with alteration for our project
*/

import { Integrations } from '@segment/analytics-next';
import { deleteCookie, getCookie, hasCookie } from 'cookies-next';

import { PREFERENCES_KEY, PREFERENCES_VERSION } from './constants';
import { CookieOrLocalStorageValue, Destination } from './types';

const _preferencesNeedUpgrade = (
    preferences: CookieOrLocalStorageValue
): boolean => {
    return preferences.version < PREFERENCES_VERSION;
};

const _upgradePreferences = (
    preferences: CookieOrLocalStorageValue
): CookieOrLocalStorageValue => {
    // Get information from old pref
    // If we have pref, but they are not the right version, we need to migrate
    const consent = preferences.consent ?? false;
    const integrations = preferences.integrations ?? {};
    savePreferences(consent, integrations);
    return {
        ...preferences,
        consent,
        integrations,
    };
};

const _replaceCookieWithLocalStorage = (
    preferences: CookieOrLocalStorageValue
): CookieOrLocalStorageValue => {
    try {
        const { consent, integrations } = preferences;
        const savedPreferences = savePreferences(consent, integrations);
        deleteCookie(PREFERENCES_KEY, { domain: window.location.hostname });
        return savedPreferences;
    } catch (error) {
        console.error('[Analytics] Could not replace preferences');
        return preferences;
    }
};

// TODO: harden against invalid cookies
// TODO: harden against different versions of cookies
export const getSavedPreferences = ():
    | CookieOrLocalStorageValue
    | undefined => {
    const localStorageItem = localStorage.getItem(PREFERENCES_KEY);

    // TODO: use a guard instead of inferring type
    if (localStorageItem) {
        const parsedLocalStorageItem = JSON.parse(
            localStorageItem
        ) as CookieOrLocalStorageValue;

        if (_preferencesNeedUpgrade(parsedLocalStorageItem)) {
            return _upgradePreferences(parsedLocalStorageItem);
        } else {
            // We have the right local storage version, so we can use it
            return parsedLocalStorageItem;
        }
    }

    const cookie = getCookie(PREFERENCES_KEY);

    // TODO: use a guard instead of inferring type
    if (cookie) {
        const parsedCookie = JSON.parse(cookie) as CookieOrLocalStorageValue;
        return _replaceCookieWithLocalStorage(parsedCookie);
    }
};

export const hasSavedPreferences = (): boolean => {
    if (localStorage.getItem(PREFERENCES_KEY)) {
        return true;
    }

    if (hasCookie(PREFERENCES_KEY)) {
        const preferences = getSavedPreferences();
        if (preferences) _replaceCookieWithLocalStorage(preferences);
        return true;
    }

    return false;
};

export const fetchDestinationForWriteKey = async (
    cdnHost: string,
    writeKey: string
): Promise<Destination[]> => {
    const res = await fetch(
        `https://${cdnHost}/v1/projects/${writeKey}/integrations`
    );

    if (!res.ok) {
        throw new Error(
            `Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`
        );
    }

    const destinations = await res.json();

    // Rename creationName to id to abstract the weird data model
    for (const destination of destinations) {
        // Because of the legacy Fullstory integration the creationName for this integration is the `name`
        if (destination.name === 'Fullstory (Actions)') {
            destination.id = destination.name;
        } else {
            destination.id = destination.creationName;
        }
        delete destination.creationName;
    }

    return destinations;
};

export const savePreferences = (
    consent: boolean,
    integrations: Integrations
): CookieOrLocalStorageValue => {
    // Set
    const integrationsToSave =
        process.env.NODE_ENV == 'development'
            ? { ...integrations }
            : integrations ?? {};
    console.log('[Analytics] Saving preferences', integrationsToSave);
    const value: CookieOrLocalStorageValue = {
        consent,
        version: PREFERENCES_VERSION,
        integrations: integrationsToSave,
    };
    localStorage.setItem(PREFERENCES_KEY, JSON.stringify(value));
    return value;
};
