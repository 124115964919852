import { FORM_FILE_UPLOAD_ATTACHMENT_PREFIX } from '@constants';
import { TrackEvent } from '@enums';
import {
    CustomerInput,
    CustomerInputAttachmentCreate,
    CustomerInputData,
    CustomerInputType,
    ModelFile,
} from '@hello-pimster/pimster-crm-sdk';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { useApiCrm } from '@lib/pimster-crm/provider/ApiCrmProvider';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useState } from 'react';

export type AttachmentKey =
    `${typeof FORM_FILE_UPLOAD_ATTACHMENT_PREFIX}${string}`;

export const isAttachmentKey = (value: unknown): value is AttachmentKey => {
    if (typeof value !== 'string') return false;
    if (!value.startsWith(FORM_FILE_UPLOAD_ATTACHMENT_PREFIX)) return false;

    return true;
};

export interface UseFormSubmitProps {
    formName: string;
    formUID: string;
    isProductRegistration: boolean;
    onSuccess?:
        | ((
              data: CustomerInput,
              variables: UseFormSubmitMutateArgs,
              context: unknown
          ) => unknown)
        | undefined;
    onError?:
        | ((
              error: AxiosError,
              variables: UseFormSubmitMutateArgs,
              context: unknown
          ) => unknown)
        | undefined;
}

export interface UseFormSubmitMutateArgs {
    data: Omit<CustomerInputData, '_type' | 'submittedAt'> & {
        [key: string]: unknown;
        [key: AttachmentKey]: ModelFile;
    };
    customerInputType: CustomerInputType;
}

export type UseFormSubmitMutate = UseMutateFunction<
    CustomerInput,
    AxiosError,
    UseFormSubmitMutateArgs,
    unknown
>;

export const useFormSubmit = ({
    formName,
    formUID,
    isProductRegistration,
    onSuccess,
    onError,
}: UseFormSubmitProps) => {
    const { client } = useApiCrm();
    const { track } = useAnalytics();
    const {
        query: { company, product },
    } = useRouter();
    const [error, setError] = useState<AxiosError>();
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

    const { mutate, isPending } = useMutation<
        CustomerInput,
        AxiosError,
        UseFormSubmitMutateArgs,
        unknown
    >({
        mutationFn: async ({
            data,
            customerInputType,
        }: UseFormSubmitMutateArgs) => {
            track(TrackEvent.FormSubmitted, {
                name: formName,
                form_uid: formUID,
            });

            const attachments: CustomerInputAttachmentCreate[] = Object.entries(
                data
            ).reduce<CustomerInputAttachmentCreate[]>((acc, entries) => {
                if (isAttachmentKey(entries[0]) && data[entries[0]]) {
                    const name = entries[0].slice(
                        FORM_FILE_UPLOAD_ATTACHMENT_PREFIX.length
                    );

                    acc.push({ name, file: data[entries[0]] });
                    delete data[entries[0]];
                }
                return acc;
            }, []);

            const res = await client.customerInputControllerCreate({
                customerInputCreate: {
                    isProductRegistration,
                    type: customerInputType,
                    form: { UID: formUID, name: formName },
                    product: { cmsUID: `${company}::${product}` },
                    // @ts-ignore
                    data: {
                        ...data,
                        _type: customerInputType,
                        submittedAt: new Date().toISOString(),
                    },
                    attachments,
                },
            });

            return res.data;
        },
        onSuccess: (...params) => {
            setIsSuccessful(true);

            return onSuccess?.(...params);
        },
        onError: (error, variables, context) => {
            track(TrackEvent.FormError, {
                name: formName,
                form_uid: formUID,
                error: error.message,
            });
            setError(error);
            onError?.(error, variables, context);
            console.warn(
                "[useFormSubmit]: Unable to send form's data: ",
                error
            );
        },
    });

    return {
        error,
        isSuccessful,
        isSubmitting: isPending,
        onSubmit: mutate,
    };
};
