import { AdditionalProductModalPreview } from '@components/additionalProducts/AdditionalProductModalPreview';
import { RenderCtaComponent } from '@components/utils/cta/custom/RenderCtaComponent';
import { RichText } from '@components/utils/RichText';
import { ModalCloseReason, OutboundLinkContext } from '@enums';
import { ModalElementsDynamicZoneComponent } from '@types';

interface Props {
    component: ModalElementsDynamicZoneComponent;
    onCloseModal: (reason: ModalCloseReason) => void;
}

export const RenderDefaultModalComponent = ({
    component,
    onCloseModal,
}: Props) => {
    switch (component.__typename) {
        case 'ComponentDisplayRichText':
            return <RichText element={component} />;

        case 'ComponentContentAdditionalProduct':
            return (
                <AdditionalProductModalPreview
                    additionalProduct={component.Additional_product!}
                />
            );

        case 'ComponentCtaEventCallToAction':
        case 'ComponentCtaLinkCallToAction':
        case 'ComponentCtaLockedContentCallToAction':
            return (
                <RenderCtaComponent
                    ctaComponent={component}
                    outboundLinkContext={OutboundLinkContext.Modal}
                    onCloseModal={onCloseModal}
                />
            );

        default:
            return null;
    }
};
