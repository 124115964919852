import { AnalyticsBrowser } from '@segment/analytics-next';
import { setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';

import { AJS_COOKIE_OPTIONS } from './constants';

export const extractFilter = (obj: Record<string, unknown>) => {
    const res: Record<string, unknown> = {};
    for (const prop in obj) {
        if (prop !== 'event') res[prop] = obj[prop];
    }
    return res;
};

export const setUserCookies = async (analytics: AnalyticsBrowser) => {
    const user = await analytics.user();
    const anonymousId = user.anonymousId();
    const userId = user.id();
    const group = await analytics.group();
    const groupId = group.id();

    const cookieOptions: OptionsType = {
        ...AJS_COOKIE_OPTIONS,
        domain: window.location.hostname,
    };
    anonymousId && setCookie('ajs_anonymous_id', anonymousId, cookieOptions);
    userId && setCookie('ajs_user_id', userId, cookieOptions);
    groupId && setCookie('ajs_group_id', groupId, cookieOptions);
};
