import FormButton from '@components/utils/cta/custom/formButton';
import { FORM } from '@constants';
import { OutboundLinkContext } from '@enums';
import { UseFormSubmitMutate } from '@hooks';
import { VStack } from '@lib/uikit';
import { SinglePageCustomForm as SinglePageCustomFormType } from '@types';
import { getInitialValues, getValidationSchema } from '@utils/form/custom';
import { Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { RenderFormElementComponent } from './RenderFormElementComponent';


type Props = {
    form: SinglePageCustomFormType;
    outboundLinkContext: OutboundLinkContext;
    isSubmitting: boolean;
    onSubmit: UseFormSubmitMutate;
};

export const SinglePageCustomForm = ({
    form,
    outboundLinkContext,
    onSubmit,
}: Props) => {
    const { t } = useTranslation(FORM);

    const initialValues = useMemo(() => {
        return getInitialValues(form.Elements ?? []);
    }, [form.Elements]);

    const validationSchema = useMemo(() => {
        return getValidationSchema({
            fields: form.Elements ?? [],
            t,
        });
    }, [form.Elements, t]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(data) => onSubmit({ data, customerInputType: 'CUSTOM' })}
        >
            <VStack spacing='xs'>
                {form.Elements.map((element, index) => (
                    <RenderFormElementComponent
                        key={index}
                        formElementComponent={element}
                        companyName={form.Company?.Name || ''}
                        outboundLinkContext={outboundLinkContext}
                    />
                ))}
                <FormButton text={form.CtaText || t('submit')} />
            </VStack>
        </Formik>
    );
};
