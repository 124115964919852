import { Body, Heading2 } from '@components/utils/text';
import { HOME } from '@constants';
import { Enum_Modals_Type, ModalFragment } from '@graphql/generated/graphql';
import { VStack } from '@lib/uikit';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

interface Props {
    modal: ModalFragment;
}

export const CustomModalDefaultContent = ({ modal }: Props) => {
    const { t } = useTranslation(HOME);

    const isForLockedModal = useMemo(() => {
        if (modal.Type !== Enum_Modals_Type.LockedExperience) {
            return false;
        }

        return !modal.Elements.find(
            (el) => el.__typename === 'ComponentDisplayRichText'
        );
    }, [modal]);

    if (isForLockedModal) {
        return (
            <VStack align='center' textAlign='center' spacing='sm'>
                <Heading2>{t('locked.modal.title')}</Heading2>
                <Body>{t('locked.modal.description')}</Body>
            </VStack>
        );
    }

    return null;
};
