import { colors } from './colors';

export const palette = {
    mode: 'light',
    actions: {
        disabled: colors.grey_300,
        hover: colors.grey_100,
    },
    background: {
        paper: colors.white,
        alt: colors.grey_50,
        default: colors.white,
    },
    common: {
        black: colors.black,
        white: colors.white,
    },
    divider: colors.grey_100,
    error: {
        main: '#f44336',
        light: '#e57373',
        dark: '#d32f2f',
        contrastText: colors.dark,
    },
    grey: {
        50: colors.grey_50,
        100: colors.grey_100,
        200: colors.grey_200,
        300: colors.grey_300,
        400: colors.grey_400,
        '400_transparent': colors.grey_400_transparent,
        500: colors.grey_500,
        600: colors.grey_600,
        700: colors.grey_700,
        800: colors.grey_800,
        900: colors.grey_900,
    },
    primary: {
        main: colors.black,
        light: colors.grey_400,
        dark: colors.grey_700,
        contrastText: colors.white,
    },
    secondary: {
        main: colors.white,
        light: colors.grey_400,
        dark: colors.medium,
        contrastText: colors.dark,
    },
    success: {
        main: colors.green,
    },
    text: {
        primary: colors.black,
        secondary: colors.grey_400,
        light: colors.grey_200,
        disabled: colors.light,
    },
    whiteAlpha: {
        50: colors.whiteAlpha_50,
        100: colors.whiteAlpha_100,
        200: colors.whiteAlpha_200,
        300: colors.whiteAlpha_300,
        400: colors.whiteAlpha_400,
        500: colors.whiteAlpha_500,
        600: colors.whiteAlpha_600,
        700: colors.whiteAlpha_700,
        800: colors.whiteAlpha_800,
        900: colors.whiteAlpha_900,
    },
};
