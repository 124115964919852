const greys = {
    grey_50: `#f5f5f5`,
    grey_100: `#ededed`,
    grey_200: `#d6d6d6`,
    grey_300: `#b5b5b5`,
    grey_400: `#8f8f8f`,
    grey_400_transparent: `#8f8f8f`,
    grey_500: `#707070`,
    grey_600: `#575757`,
    grey_700: `#404040`,
    grey_800: `#303030`,
    grey_900: `#2e2e2e`,
};

const whites = {
    white: `#ffffff`,
    whiteAlpha_50: 'rgba(255, 255, 255, 0.04)',
    whiteAlpha_100: 'rgba(255, 255, 255, 0.06)',
    whiteAlpha_200: 'rgba(255, 255, 255, 0.08)',
    whiteAlpha_300: 'rgba(255, 255, 255, 0.16)',
    whiteAlpha_400: 'rgba(255, 255, 255, 0.24)',
    whiteAlpha_500: 'rgba(255, 255, 255, 0.36)',
    whiteAlpha_600: 'rgba(255, 255, 255, 0.48)',
    whiteAlpha_700: 'rgba(255, 255, 255, 0.64)',
    whiteAlpha_800: 'rgba(255, 255, 255, 0.80)',
    whiteAlpha_900: 'rgba(255, 255, 255, 0.92)',
};

export const colors = {
    ...greys,
    ...whites,
    black: `#2e2e2e`,
    dark: `#404040`,
    green: `#4bde97`,
    light: `#ededed`,
    medium: `#8f8f8f`,
    medium_transparent: `#8f8f8f`,
};
