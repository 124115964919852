import styled from '@emotion/styled';
import React from 'react';

type PropsType = { children: React.ReactNode };

const Container = styled.div`
    overflow-x: auto;
    margin-block: ${(props) => props.theme.spacing.md};
`;

const Table = styled.table`
    border-collapse: collapse;
    th,
    td {
        border: 1px solid;
        border-color: ${(props) => props.theme.palette.divider};
        padding: ${(props) => props.theme.spacing.sm};
        vertical-align: top;
    }
    th {
        background-color: ${(props) => props.theme.palette.background.paper};
        border-bottom-width: 2px;
    }
    img {
        margin-block: 0;
    }
`;

const TableMarkdown: React.FC<PropsType> = ({ children }) => {
    return (
        <Container className='table_container'>
            <Table>{children}</Table>
        </Container>
    );
};

export default TableMarkdown;
