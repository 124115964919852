import { Theme, css } from '@emotion/react';

const fonts = (theme: Theme) => css`
    * {
        color: ${theme.palette.text.primary};
    }

    html {
        background: ${theme.palette.background.default};
        color: ${theme.palette.text.primary};
        font-family: ${theme.typography.fontFamily};
    }

    h1 {
        font: ${theme.fonts.heading1};
    }

    h2 {
        font: ${theme.fonts.heading2};
    }
    h3 {
        font: ${theme.fonts.heading3};
    }
    h4 {
        font: ${theme.fonts.heading4};
    }
    h5 {
        font: ${theme.fonts.heading5};
    }

    p {
        font: ${theme.fonts.body};
    }

    button {
        padding-block: ${theme.components.buttons.size === 'xs'
            ? theme.spacing.xs
            : theme.spacing.sm};
        font: ${theme.fonts.button};
    }
`;

export default fonts;
