import { IS_NOT_ANONYMOUS_REQUIREMENTS } from '@constants';
import { CustomerInput } from '@hello-pimster/pimster-crm-sdk';
import { COOKIE_ID_HEADER } from '@lib/pimster-crm/constants';
import { useApiCrm } from '@lib/pimster-crm/provider/ApiCrmProvider';
import { isCustomerInput } from '@lib/pimster-crm/types/guards';
import { useQuery } from '@tanstack/react-query';
import { objectHasOneOfKeys } from '@utils/objectHasKey';
import * as Axios from 'axios';
import { isBoolean } from 'lodash';
import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';

interface UserContextProps {
    isAuthenticated: boolean;
}

const UserContext = createContext<UserContextProps>({} as UserContextProps);

export const UserProvider = ({ children }: PropsWithChildren) => {
    const { client, headers, addInterceptor } = useApiCrm();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const {
        data: me,
        isLoading,
        isError,
    } = useQuery({
        enabled: Boolean(headers[COOKIE_ID_HEADER]),
        retry: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['myCustomerProfile'],
        queryFn: () => client.customerProfileControllerFindMe(),
        staleTime: Infinity,
    });

    useEffect(() => {
        if (!isLoading && !isError && me && isBoolean(me.data.isAnonymous)) {
            setIsAuthenticated(!me.data.isAnonymous);
        }
    }, [me, isLoading, isError]);

    useEffect(() => {
        addInterceptor({
            fulfilled: (res: Axios.AxiosResponse<CustomerInput>) => {
                const hasJustAuthenticated = objectHasOneOfKeys(
                    res.data.data,
                    IS_NOT_ANONYMOUS_REQUIREMENTS
                );

                if (hasJustAuthenticated) {
                    setIsAuthenticated(true);
                }

                return res;
            },
            condition: (res) => isCustomerInput(res.data),
        });
    }, [addInterceptor]);

    return (
        <UserContext.Provider value={{ isAuthenticated }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
