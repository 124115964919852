import { colors } from './colors';

export const components = {
    buttons: {
        size: 'xs',
    },
    ripple: {
        color: colors.grey_200,
    },
};
