import styled from '@emotion/styled';
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from '@headlessui/react';
import {
    FormControl,
    FormControlProps,
    HStack,
    InputGroup,
    lineClamp,
} from '@lib/uikit';
import { getTranslatedCountryName } from '@utils/intl';
import { getUserPreferredLanguage } from '@utils/language';
import { useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import { ChangeEvent, Fragment, useMemo } from 'react';
import {
    CountryIso2,
    FlagImage,
    defaultCountries,
    parseCountry,
    usePhoneInput,
} from 'react-international-phone';

import 'react-international-phone/style.css';
import { Caption } from '../text';

interface Props extends Omit<FormControlProps, 'children'> {
    placeholder?: string;
}

const Options = styled(ListboxOptions)`
    height: 220px;
    min-width: 240px;
    max-width: 240px;
    overflow: scroll;
    list-style: none;
    padding: ${({ theme }) => theme.spacing.xs};
    border-radius: ${({ theme }) => theme.shape.radius.default};
    box-shadow: ${({ theme }) => theme.shadows.default};
    background: ${({ theme }) => theme.palette.background.paper};
`;

const Button = styled(ListboxButton)`
    cursor: pointer;
    background-color: transparent;
`;

const Li = styled.li<{ isActive: boolean; isSelected: boolean }>`
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing.xs};
    border-radius: ${({ theme }) => theme.shape.radius.default};
    background-color: ${({ theme, isActive, isSelected }) =>
        isActive
            ? theme.palette.grey[100]
            : isSelected
              ? theme.palette.grey[200]
              : theme.palette.background.default};
`;

const CountryName = styled.p`
    ${lineClamp(1)}
`;

export const PhoneInput = ({
    name,
    label,
    placeholder,
    isRequired = false,
    errorMessage,
}: Props) => {
    const { locale } = useRouter();
    const {
        inputRef,
        inputValue,
        country: selectedCountry,
        setCountry: setSelectedCountry,
        handlePhoneValueChange,
    } = usePhoneInput({
        defaultCountry: getUserPreferredLanguage().toLocaleLowerCase(),
        countries: defaultCountries,
        disableCountryGuess: true,
        forceDialCode: true,
    });
    const { getFieldProps, setFieldValue } = useFormikContext();

    const countries = useMemo(() => {
        if (!locale || locale === 'en') {
            return defaultCountries.map(parseCountry);
        }

        return defaultCountries
            .map((country) => {
                const parsedCountry = parseCountry(country);
                return {
                    ...parsedCountry,
                    name:
                        getTranslatedCountryName(parsedCountry.iso2, locale) ||
                        parsedCountry.name,
                };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [locale]);

    const handleCountryChange = (newCountry: CountryIso2) => {
        setSelectedCountry(newCountry);
    };

    const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newPhone = e.target.value;
        handlePhoneValueChange(e);
        setFieldValue(name!, newPhone);
    };

    return (
        <FormControl
            key={name}
            name={name}
            label={label}
            isRequired={isRequired}
            errorMessage={errorMessage}
        >
            <InputGroup
                {...getFieldProps(name!)}
                ref={inputRef}
                value={inputValue}
                placeholder={placeholder}
                onChange={handlePhoneChange}
                leftElement={
                    <Listbox
                        value={selectedCountry.iso2}
                        onChange={handleCountryChange}
                    >
                        <Button as={ListboxButton}>
                            <FlagImage
                                iso2={selectedCountry.iso2}
                                style={{ display: 'flex' }}
                            />
                        </Button>
                        <Options>
                            {countries.map((country) => (
                                <ListboxOption
                                    as={Fragment}
                                    key={country.iso2}
                                    value={country.iso2}
                                >
                                    {({ focus, selected }) => (
                                        <Li
                                            isActive={focus}
                                            isSelected={selected}
                                        >
                                            <HStack spacing='xs'>
                                                <FlagImage
                                                    iso2={country.iso2}
                                                />
                                                <CountryName>
                                                    {country.name}
                                                </CountryName>
                                                <Caption>
                                                    +{country.dialCode}
                                                </Caption>
                                            </HStack>
                                        </Li>
                                    )}
                                </ListboxOption>
                            ))}
                        </Options>
                    </Listbox>
                }
            />
        </FormControl>
    );
};
