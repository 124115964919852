import { SerializedStyles, StyledProps, Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { BackgroundColor, isSpace, Space } from '@lib/uikit/theme/types';
import { CSSProperties, ComponentProps } from 'react';

type CustomProps = {
    variant?: 'blurred' | 'filled' | 'elevated' | 'outline';
    backgroundColor?: BackgroundColor;
    cursor?: CSSProperties['cursor'];
    padding?: Space | string;
    paddingX?: Space | string;
    paddingY?: Space | string;
};

export type CardProps = CustomProps & ComponentProps<'div'>;

const getVariant = ({
    theme,
    variant = 'elevated',
}: CustomProps & { theme: Theme }): SerializedStyles => {
    const blurredVariantProps: CSSProperties = {
        border: 'none',
        backdropFilter: theme.blurs.default,
        background: theme.palette.whiteAlpha[500],
    };

    const elevatedVariantProps: CSSProperties = {
        boxShadow: theme.shadows.default,
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
    };

    const filledVariantProps: CSSProperties = {
        backgroundColor: theme.palette.background.alt,
        borderColor: theme.palette.background.alt,
    };

    const outlineVariantProps: CSSProperties = {
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
    };

    return css({
        ...(variant === 'blurred' && blurredVariantProps),
        ...(variant === 'elevated' && elevatedVariantProps),
        ...(variant === 'filled' && filledVariantProps),
        ...(variant === 'outline' && outlineVariantProps),
    });
};

const getPadding = (
    theme: Theme,
    ...paddings: (Space | string | undefined)[]
): Space | string =>
    paddings.reduce((acc, padding) => {
        if (!acc) return isSpace(padding) ? theme.spacing[padding] : padding;

        return acc;
    }, undefined) || theme.spacing['sm'];

export const Card = styled.div<StyledProps<CardProps>>`
    overflow: hidden;
    position: relative;
    border-width: thin;
    border-style: solid;
    word-break: break-word;
    // safari fix for overflow hidden with border radius
    transform: translateZ(0);
    cursor: ${({ cursor }) => cursor || 'pointer'};

    padding-top: ${({ theme, paddingY, padding }) =>
        getPadding(theme, padding, paddingY)};
    padding-bottom: ${({ theme, paddingY, padding }) =>
        getPadding(theme, padding, paddingY)};
    padding-left: ${({ theme, padding, paddingX }) =>
        getPadding(theme, padding, paddingX)};
    padding-right: ${({ theme, padding, paddingX }) =>
        getPadding(theme, padding, paddingX)};

    border-radius: ${(props) => props.theme.shape.radius.default};
    ${getVariant};
`;
