import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';

import { customIcons } from './customIcons';
import iconList from './iconList';

export const getIconSizeInRem = (size: IconProps['size']) => {
    switch (size) {
        case 'xlarge':
            return '2.5rem';
        case 'large':
            return '2rem';
        case 'medium':
            return '1.5rem';
        case 'small':
            return '1rem';
        default:
            return 'inherit';
    }
};

export type IconName = keyof typeof customIcons | keyof typeof iconList;

export type IconProps = {
    name: IconName;
    size?: 'inherit' | 'xlarge' | 'large' | 'medium' | 'small';
    color?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Div = styled.div`
    display: flex;
    user-select: none;
`;

const CustomIcon = styled.span<StyledProps<{ size: IconProps['size'] }>>`
    display: flex;
    align-items: center;
    justify-content: center;
    --size: ${({ size }) => getIconSizeInRem(size)};
    & > svg {
        fill: ${({ color }) => color};
        stroke: ${({ color }) => color};
        height: var(--size);
        width: var(--size);
    }
`;

const Span = styled.span<StyledProps<IconProps>>`
    font-size: ${({ size }) => getIconSizeInRem(size)};
    color: ${({ color, theme }) => color || theme.palette.text.primary};
`;

const Icon: React.FC<IconProps> = ({
    name,
    size = 'inherit',
    color = 'inherit',
    ...props
}: IconProps) => {
    return (
        <Div {...props}>
            {Object.keys(customIcons).includes(name as string) ? (
                <CustomIcon color={color} size={size}>
                    {customIcons[name as keyof typeof customIcons]}
                </CustomIcon>
            ) : (
                <Span
                    className='material-icons-round'
                    size={size}
                    color={color}
                    data-icon-name={name as string}
                >
                    {name as string}
                </Span>
            )}
        </Div>
    );
};

export default Icon;
