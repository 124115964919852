import Markdown from '@components/utils/mdText';
import styled from '@emotion/styled';
import { RichTextFragment } from '@graphql/generated/graphql';
import StrapiImage from '@lib/image/strapiImage';

type PropsType = {
    element: RichTextFragment;
};

const TextContainer = styled.div`
    text-align: left;
`;

const ImageComponent = styled(StrapiImage)`
    max-width: 100%;
    border-radius: ${(props) => props.theme.spacing.md};
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-block: ${(props) => props.theme.spacing.sm};
`;

export const RichText = ({ element }: PropsType) => {
    return (
        <TextContainer>
            <Markdown
                markdown={element.RichText}
                ImageComponent={ImageComponent}
            />
        </TextContainer>
    );
};
