import styled from '@emotion/styled';

export const Heading1 = styled.h1``;

export const Heading2 = styled.h2``;

export const Heading3 = styled.h3``;

export const Heading4 = styled.h4``;

export const Heading5 = styled.h5``;

export const Body = styled.p`
    color: ${(props) => props.color || props.theme.palette.text.primary};
`;

export const Caption = styled.p`
    color: ${(props) => props.color || props.theme.palette.text.secondary};
    font: ${(props) => props.theme.fonts.caption};
`;
