import { useRouter } from 'next/router';
import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';

interface EditContext {
    isLoading: boolean;
    isEditMode: boolean;
}

const EditContext = createContext<EditContext>({} as EditContext);

export const EditProvider = ({ children }: PropsWithChildren) => {
    const { isReady, query } = useRouter();
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (isReady) {
            setIsEditMode(query.editMode === 'true');
        }
    }, [isReady, query]);

    return (
        <EditContext.Provider
            value={{
                isEditMode,
                isLoading: !isReady,
            }}
        >
            {children}
        </EditContext.Provider>
    );
};

export const useEditContext = () => useContext(EditContext);
