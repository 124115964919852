import { RefObject, useEffect, useState } from 'react';

export enum ScrollDirection {
    Down = 'down',
    Up = 'up',
}

export const useScrollDirection = (ref: RefObject<HTMLDivElement>) => {
    const [scrollDirection, setScrollDirection] = useState<ScrollDirection>();

    useEffect(() => {
        if (!ref.current) return;

        const savedRef = ref.current;
        let oldScrollY = savedRef.scrollTop;

        const updateScrollDirection = () => {
            const newScrollY = savedRef.scrollTop;
            const direction =
                newScrollY > oldScrollY
                    ? ScrollDirection.Down
                    : ScrollDirection.Up;
            setScrollDirection(direction);
            oldScrollY = newScrollY;
        };

        const endScroll = () => {
            setScrollDirection(undefined);
        };

        savedRef.addEventListener('scroll', updateScrollDirection);
        savedRef.addEventListener('scrollend', endScroll);

        return () => {
            savedRef?.removeEventListener('scroll', updateScrollDirection);
            savedRef?.removeEventListener('scrollend', endScroll);
        };
    }, [ref]);

    return { scrollDirection };
};
