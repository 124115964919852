import { ComponentConsentsEmail } from '@graphql/generated/graphql';

export const getDefaultEmailConsent = (
    emailConsent?: ComponentConsentsEmail | null
) => {
    if (emailConsent?.id) {
        return emailConsent.ImplicitConsent ?? false;
    } else {
        return true;
    }
};
