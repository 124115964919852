import { FileUpload } from '@hello-pimster/pimster-crm-sdk';
import { Axios, AxiosRequestConfig, AxiosResponse } from 'axios';

export const fileUploadFactory = (url: URL | string) => {
    const axios = new Axios({
        baseURL: new URL('', process.env.NEXT_PUBLIC_API_CRM_URL).toString(),
        url: url.toString(),
    });

    return async (
        data: FileUpload,
        options?: AxiosRequestConfig<FormData>
    ): Promise<AxiosResponse> => {
        const form = new FormData();

        if (typeof data.name !== 'undefined') form.append('name', data.name);
        if (typeof data.file !== 'undefined') form.append('file', data.file);
        if (typeof data.metadata !== 'undefined') {
            form.append('metadata', JSON.stringify(data.metadata));
        }
        if (typeof data.company !== 'undefined') {
            form.append('company', JSON.stringify(data.company));
        }

        const resp = await axios.postForm(url.toString(), form, options);

        resp.data = JSON.parse(resp.data);

        return resp;
    };
};
